import { RootStore } from 'src/app/stores/RootStore'
import { ActionableInsightsService } from '../service/ActionableInsightsService'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import rootStore from '../../../stores/RootStore'
import demoData from '../data/ActionableInsightsDemoData'

export class ActionableInsightsVM {
  private rootStore: RootStore
  private orgId: string

  constructor() {
    this.rootStore = rootStore
    this.orgId = rootStore.appStore.currentOrgId
    this.checkAccess()
    this.fetchSummaries(this.orgId)
  }

  @observable private isLoading = false
  @observable private isLoaded = false
  @observable public summaries: any[] = []
  @observable public selectedPeriodId: string = ''
  @observable public selectedGroupId: number = 0
  @observable public insightPeriodAnchorEl: any = null
  @observable public groupAnchorEl: any = null
  // Temporary variable
  @observable public dataAvailable: boolean = true

  public async fetchSummaries(organizationId: string) {
    const token = this.rootStore.userStore.parseUserInfo.sessionToken
    const svc = new ActionableInsightsService()
    this.isLoading = true
    try {
      const result = await svc.getList(organizationId, token)
      // this.summaries = result.data.summaries
      this.summaries = demoData
      // TODO: Replace demoData with this.summaries when making a real API call
      this.selectedPeriodId = demoData[0].PeriodId
      this.isLoaded = true
      this.isLoading = false
    } catch (error) {
      console.error('Failed to fetch actionable insights list', error)
    } finally {
      this.isLoaded = true
      this.isLoading = false
    }
  }

  public async checkAccess(attempts: number = 0) {
    if (attempts === 14) return
    if (!rootStore.organizationsStore.currentOrganization) {
      setTimeout(() => this.checkAccess(++attempts), 500)
      return
    }
    const canAccess = rootStore.appStore.canAccessActionableInsights
    const isEnabled = rootStore.organizationsStore.currentOrganization.isActionableInsightsEnabled
    if (!canAccess || !isEnabled) {
      rootStore.appStore.router.push(`/dashboard/userDashboard/`)
    }
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.rootStore?.appStore?.canAccessActionableInsights) return false
    if (!this.rootStore?.organizationsStore?.currentOrganization?.isActionableInsightsEnabled)
      return false
    return true
  }

  @computed
  public get spinnerShown(): boolean {
    return this.isLoading && !this.isLoaded
  }

  @computed
  private get hasSummaries(): boolean {
    return this.summaries.length > 0
  }

  @computed
  public get emptyStateMessageShown(): boolean {
    return !this.isLoading && this.isLoaded && !this.hasSummaries
  }

  @action
  public setSelectedPeriod(periodId: string) {
    this.selectedPeriodId = periodId
    this.closeInsightPeriodMenu()
  }

  @action
  public setSelectedGroup(groupId: number) {
    this.selectedGroupId = groupId
    this.closeGroupMenu()
  }

  @computed
  public get selectedGroup() {
    return this.groupOptions.filter((e) => e.value === this.selectedGroupId)[0]
  }

  @computed
  private get selectedPeriod() {
    // TODO: Replace demoData with this.summaries when making a real API call
    return demoData.find((e) => e.PeriodId === this.selectedPeriodId)
  }

  @computed
  public get periodOptions() {
    // TODO: Replace demoData with this.summaries when making a real API call
    return demoData.map((e) => ({
      value: e.PeriodId,
      label: e.PeriodName,
    }))
  }

  @computed
  public get groupOptions() {
    // TODO: Replace demoData with this.summaries when making a real API call
    if (!this.selectedPeriod) return []
    const options = this.selectedPeriod.Groups.map((e) => {
      return {
        value: e.GroupId,
        label: e.GroupName,
      }
    })
    options.unshift({ value: 0, label: 'All' })
    return options
  }

  @computed
  public get selectedPeriodName(): string {
    if (!this.selectedPeriod) return ''
    return this.selectedPeriod.PeriodName
  }

  @computed
  public get selectedGroupName(): string {
    if (!this.selectedGroup) return ''
    return this.selectedGroup.label
  }

  @computed
  public get insightPeriodDates(): string {
    if (!this.selectedPeriod) return ''
    return this.selectedPeriod.PeriodDates
  }

  @computed
  public get insightPeriodMenuShown(): boolean {
    return this.insightPeriodAnchorEl !== null
  }

  @action
  public openInsightPeriodMenu(e: any) {
    this.insightPeriodAnchorEl = e
  }

  @action
  public closeInsightPeriodMenu() {
    this.insightPeriodAnchorEl = null
  }

  @computed
  public get groupMenuShown(): boolean {
    return this.groupAnchorEl !== null
  }

  @action
  public openGroupMenu(e: any) {
    this.groupAnchorEl = e
  }

  @action
  public closeGroupMenu() {
    this.groupAnchorEl = null
  }

  @computed
  public get sentimentScore(): string {
    // TODO: Use real values when available
    if (this.dataAvailable) return '6'
    return '--'
  }

  @computed
  public get responseScore(): string {
    // TODO: Use real values when available
    if (this.dataAvailable) return '6'
    return '--'
  }

  @computed
  public get motivationScore(): string {
    // TODO: Use real values when available
    if (this.dataAvailable) return '6'
    return '--'
  }

  @computed
  public get workloadScore(): string {
    // TODO: Use real values when available
    if (this.dataAvailable) return '2'
    return '--'
  }

  public get dataUnavailableMessage(): string {
    return 'There is not enough data to produce actionable insights for this period.'
  }

  public get personalFramingScore(): number {
    // TODO: Use real values when available
    return 37
  }

  @computed
  public get personalFramingInsights(): string[] {
    if (this.dataAvailable)
      return [
        'Many employees express high motivation due to recent skill development opportunities and growth initiatives.',
      ]
    return []
  }

  @computed
  public get hasPersonalFramingInsights(): boolean {
    return this.personalFramingInsights.length > 0
  }

  public get relationalFramingScore(): number {
    // TODO: Use real values when available
    return 16
  }

  @computed
  public get relationalFramingInsights(): string[] {
    // Temporarily removed for RIP2-275 testing
    return []
  }

  @computed
  public get hasRelationalFramingInsights(): boolean {
    return this.relationalFramingInsights.length > 0
  }

  public get operationalFramingScore(): number {
    // TODO: Use real values when available
    return 82
  }

  @computed
  public get operationalFramingInsights(): string[] {
    if (this.dataAvailable)
      return [
        '85% of projects were delivered on schedule, contributing to a 10% rise in client satisfaction scores.',
        'Recent process improvements have led to faster decision-making, with teams reporting a 30% decrease in time spent on approvals and consultations.',
      ]
    return []
  }

  @computed
  public get hasOperationalFramingInsights(): boolean {
    return this.operationalFramingInsights.length > 0
  }
}
