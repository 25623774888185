import { action, observable, computed, reaction } from 'mobx'
import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import moment from 'moment'
import Parse from 'parse'
import env from '../../../../env'
import ProfilePictureVM from './ProfilePictureVM'
import { UserBecomeDialogVM } from './UserBecomeDialogVM'
import { AudienceUsersAGGridVM } from './AudienceUsersAGGridVM'
import { RootStore } from '../../../stores/RootStore'
import { IParticipant } from '../../../participants-select/interfaces/IParticipant'
import { AudienceMemberType } from '../../../audience-members/types/AudienceMemberType'
import { Group } from '../../../groups/aggregate/Group'
import { Role } from '../../../roles/aggregate/Role'
import { PagedDataVM } from '../../../shared/view-models/PagedDataVM'
import { ParticipantsSelectVM } from '../../../participants-select/view-models/ParticipantsSelectVM'
import { OrganizationUser } from '../../aggregate/OrganizationUser'
import { OrganizationUsersService } from '../../service/OrganizationUsersService'
import { IUserDetailsDTO } from '../../dtos/IUserDetailsDTO'
import { UserOneTimeUsePasscodeDialogVM } from './UserOneTimeUsePasscodeDialogVM'
import { ParseService } from '../../../services/ParseService'
import { PasswordStrengthMeterVM } from '../../../shared/password-strength/PasswordStrengthMeterVM'
import { AuthenticationService } from '../../../auth/services/AuthenticationService'
import { IOrganizationUsersBulkRequest } from '../../interfaces/IOrganizationUsersBulkRequest'
import { CMSAvatarDeleteService } from '../../../cms-avatars/service/CMSAvatarDeleteService'
import { ICMSAvatarDeleteRequest } from '../../../cms-avatars/interfaces/ICMSAvatarDeleteRequest'

export class OrganizationUserEditVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.profilePicVM = new ProfilePictureVM(this.rootStore, this)
    this.becomeDialogVM = new UserBecomeDialogVM(this.rootStore, this)
    this.userOneTimeUsePasscodeDialogVM = new UserOneTimeUsePasscodeDialogVM(this.rootStore, this)
    this.rootStore.audienceMembersStore.loadAudienceMembers()
    this.loadAudienceParticipantsSelect()

    this.meterVM = new PasswordStrengthMeterVM(rootStore.localizationStore)

    reaction(
      () => this.password,
      () => {
        this.meterVM.setPassword(this.password)
      }
    )
  }

  public loadNewUser() {
    this.isOpen = true
    this.isProcessing = true
    const organizationUser = OrganizationUser.create(this.rootStore.appStore.currentOrgId)
    organizationUser.User.receiveEmails = true
    organizationUser.User.receivePushNotifications = true
    organizationUser.User.receiveTextMessages = true
    this.loadData(organizationUser)
  }

  public async loadUser(objectId: string) {
    this.isOpen = true
    this.isProcessing = true
    const svc = new OrganizationUsersService(this.rootStore)
    const orgUserDTO = await svc.getOrganizationUser(objectId)
    if (!orgUserDTO) return
    const orgUser = await this.rootStore.organizationUsersStore.updateRecordFromServer(
      orgUserDTO,
      'full'
    )
    if (!orgUser) return
    this.loadData(orgUser)
  }

  private loadAudienceParticipantsSelect() {
    this.audienceParticipantsSelectVM = new ParticipantsSelectVM(
      this.rootStore,
      false,
      false,
      true,
      true,
      false,
      true,
      false
    )
  }

  private handleUpdateFailed(errorMsg: string, objectId: string) {
    const s = this.rootStore.localizationStore.lzStrings.userEdit
    this.rootStore.organizationUsersStore.pwSnackbarVM.openSnackbar(
      `${s.error}: ${s.username_already_exist}`
    )
    this.loadUser(objectId)
  }

  @action
  public buildAvatarUrl = (
    userId: string = 'U',
    firstName: string = 'U',
    lastName: string = 'N'
  ) => {
    return `${env.var.REACT_APP_CMS_API_URL}/api/avatar/${userId}?firstName=${firstName}&lastName=${lastName}`
  }

  @action
  private loadData(organizationUser: OrganizationUser, attempts: number = 0) {
    if (attempts === 14) {
      alert('Failed to get user data. Please try reloading the page.')
      return
    }
    if (!this.isLoaded) return setTimeout(() => this.loadData(organizationUser, ++attempts), 1000)
    this.organizationUser = organizationUser.clone()
    this.objectId = organizationUser.objectId
    this.isHiddenInOrg = organizationUser.isHiddenInOrg
    this.iconURL = this.buildAvatarUrl(
      organizationUser.User.objectId,
      organizationUser.User.firstName,
      organizationUser.User.lastName
    )
    this.userId = organizationUser.userId
    this.firstName = organizationUser.User.firstName
    this.lastName = organizationUser.User.lastName
    this.email = organizationUser.User.publicEmail
    this.birthDate = organizationUser.User.birthDate
    this.roleStartDate = organizationUser.roleStartDate
    this.languagePreference = organizationUser.User.languagePreference
    this.phone = organizationUser.User.phone
    // this.nickname = organizationUser.User.nickname
    this.title = organizationUser.title ? organizationUser.title : ''
    this.roles = this.validateRoles(organizationUser.rolesAsArray)
    this.groups = this.validateGroups(organizationUser.groupsAsArray)
    this.employeeId = organizationUser.employeeId
    this.jobNumber = organizationUser.jobNumber
    this.bypassOIDCAuth = organizationUser.bypassOIDCAuth
    this.additionalAudienceMembers = organizationUser.additionalAudienceMembers
    this.audienceParticipantsSelectVM.setParticipants(
      organizationUser.additionalAudienceMembers as IParticipant[]
    )
    this.privilegeSets = organizationUser.privilegeSets ? organizationUser.privilegeSets : []

    // this.privilegeSetsFromGroupsAndRoles = organizationUser.privilegeSetsFromGroupsAndRoles
    //   ? organizationUser.privilegeSetsFromGroupsAndRoles
    //   : [] // TODO: NEED TO COMPUTE THIS

    this.privilegeSetsAddedManually = this.privilegeSets.filter(
      (set) =>
        !this.privilegeSetsFromGroupsAndRoles.map((data) => data.privilegeSetObjectId).includes(set)
    )

    this.superiors = this.trimSuperiors(organizationUser.superiors.slice())
    this.subordinates = this.trimSubordinates(organizationUser.subordinates.slice())

    this.receiveEmails = organizationUser.User.receiveEmails
    this.receivePushNotifications = organizationUser.User.receivePushNotifications
    this.receiveTextMessages = organizationUser.User.receiveTextMessages
    this.primaryRoleId = organizationUser.primaryRoleId
    this.primaryGroupId = organizationUser.primaryGroupId
    this.isProcessing = false
    this.archived = organizationUser.isArchived ? organizationUser.isArchived : false
    this.archivedDate = organizationUser.archivedDate ? organizationUser.archivedDate : ''
    this.isDisabled = organizationUser.isDisabled ? organizationUser.isDisabled : false
    this.username = organizationUser.User.username
    this.tableauLicenseType = organizationUser.User.tableauLicenseType
    this.tableauLicensePermanent = organizationUser.User.tableauLicensePermanent
    this.welcomeStatus = organizationUser.welcomeEmailStatus
      ? organizationUser.welcomeEmailStatus
      : '--'
  }

  @observable public audienceListVM: AudienceUsersAGGridVM
  @observable public organizationUser: OrganizationUser = null
  @observable public objectId: string = ''
  @observable public firstName: string = ''
  @observable public lastName: string = ''
  @observable public password: string = ''
  @observable public nickname: string = ''
  @observable public email: string = ''
  @observable public iconURL: string = ''
  @observable public birthDate: string = ''
  @observable public roleStartDate: string = ''
  @observable public phone: string = ''
  @observable public title: string = ''
  @observable public userId: string = ''
  @observable public privilegeSets: Array<string> = []
  @observable public privilegeSetsFromGroupsAndRoles: Array<any> = []
  @observable public privilegeSetsAddedManually: Array<string> = []
  @observable public roles: Array<any> = []
  @observable public groups: Array<any> = []
  @observable public superiors: Array<any> = []
  @observable public subordinates: Array<any> = []
  @observable public audienceGroups: Array<any> = []
  @observable public audienceRoles: Array<any> = []
  @observable public audienceUsers: Array<any> = []
  @observable public showPassword: boolean = false
  @observable public showRoles: boolean = false
  @observable public showGroups: boolean = false
  @observable public showSuperiors: boolean = false
  @observable public showSubordinates: boolean = false
  @observable public addAudienceTabShown: boolean = false
  @observable public audienceUsersFromAddScreen: Array<any> = []
  @observable public audienceRolesFromAddScreen: Array<any> = []
  @observable public audienceGroupsFromAddScreen: Array<any> = []
  @observable public audienceOverrides: Array<any> = []
  @observable public superiorFilter: string = ''
  @observable public subordinateFilter: string = ''
  @observable public audienceFilter: string = ''
  @observable public isDisabled: boolean = false
  @observable public disabled: boolean = false
  @observable public archived: boolean = false
  @observable public archivedDate: string = ''
  @observable public saveTried: boolean = false
  @observable public openMembers: Array<string> = []
  @observable public receivePushNotifications: boolean = false
  @observable public receiveEmails: boolean = false
  @observable public receiveTextMessages: boolean = false
  @observable public currentAudienceTabIndex: number = 0
  @observable public isProcessing: boolean = false
  @observable public employeeId: string = ''
  @observable public jobNumber: string = ''
  @observable public primaryRoleId: string = ''
  @observable public primaryGroupId: string = ''
  @observable public pagedData: PagedDataVM
  @observable public profilePicVM: ProfilePictureVM = null
  @observable public becomeDialogVM: UserBecomeDialogVM = null
  @observable public additionalAudienceMembers: Array<any> = []
  @observable public audienceParticipantsSelectVM: ParticipantsSelectVM = null
  @observable public roleFilter: string = ''
  @observable public groupFilter: string = ''
  @observable public virtualListParent: any = null
  @observable public audienceMembersAdded: boolean = false
  @observable public bypassOIDCAuth: boolean = false
  @observable public languagePreference: string = ''
  @observable public isOpen: boolean = false
  @observable public currentTabIndex: number = 0
  @observable public newUserJustSaved: string = ''
  @observable public userJustDisabled: string = ''
  @observable public userJustDeleted: string = ''
  @observable public userJustArchived: string = ''
  @observable public userJustWasHidden: string = ''
  @observable public username: string = ''
  @observable public tableauLicenseType: string = ''
  @observable public tableauLicensePermanent: boolean = false
  @observable public isHiddenInOrg: boolean = false
  @observable public userOneTimeUsePasscodeDialogVM: UserOneTimeUsePasscodeDialogVM = null
  @observable public meterVM: PasswordStrengthMeterVM = null
  @observable public hiddenStatusChanged: boolean = false
  @observable public welcomeStatus: string = ''
  @observable public isFetching: boolean = false
  @observable public isConfimDeleteDialogOpen: boolean = false

  public get isNewUser(): boolean {
    return !Boolean(this.userId)
  }

  @computed
  public get isLoaded(): boolean {
    if (!this.rootStore.rolesStore.loaded) return false
    if (!this.rootStore.groupsStore.loaded) return false
    return true
  }

  @action
  public toggleHidden(): void {
    this.hiddenStatusChanged = true
    this.isHiddenInOrg = !this.isHiddenInOrg
  }

  @computed
  public get fullAudience() {
    return []
    // return [...this.audienceUsersData, ...this.audienceRolesData, ...this.audienceGroupsData]
  }

  @computed
  public get primaryRoleOptions(): Array<Role> {
    let roles = []
    this.roles.forEach((role: string) => {
      const foundRole = this.rootStore.rolesStore.getRole(role)
      if (!foundRole) return

      if (foundRole.name === 'Administrator' || foundRole.name === 'User') return

      roles.push(foundRole)
    })

    return roles
  }

  @computed
  public get primaryGroupOptions(): Array<Group> {
    let groups = []
    this.groups.forEach((group: string) => {
      const foundGroup = this.rootStore.groupsStore.getGroup(group)
      if (!foundGroup) return

      groups.push(foundGroup)
    })

    return groups
  }

  @computed
  public get isUserOwned(): boolean {
    if (this.userId === this.rootStore.appStore.currentUserId) return true
  }

  @computed
  public get isUserAdmin() {
    return this.rootStore.appStore.canManageUsers
  }

  @computed
  public get isEditable(): boolean {
    if (this.isOrgAdmin) return true
    if (this.isUserAdmin) return true
    return false
  }

  @computed
  public get isArchived(): boolean {
    return this.archived
  }

  @computed
  public get virtualListHeight(): number {
    if (!this.virtualListParent) return 550
    return this.virtualListParent.clientHeight - 100
  }

  @computed
  public get primaryRole(): Role {
    if (!this.primaryRoleId) return null
    return this.rootStore.rolesStore.getRole(this.primaryRoleId)
  }

  @computed
  public get primaryGroup(): Group {
    if (!this.primaryGroupId) return null
    return this.rootStore.groupsStore.getGroup(this.primaryGroupId)
  }

  @computed
  public get saveDisabled(): boolean {
    if (
      !this.lastName ||
      !this.firstName ||
      !this.hasEmailOrUsername ||
      !this.password ||
      this.isEmailTaken
    )
      return true
    return false
  }

  @computed
  public get hasEmailOrUsername(): boolean {
    if (this.username) return true
    if (this.email) return true
    return false
  }

  @action
  public setLanguagePreference(val) {
    this.languagePreference = val
  }

  @computed
  public get languageOptions(): string[] {
    const languages = []
    if (this.rootStore.organizationsStore.currentOrganization.hasEnglish) languages.push('English')
    if (this.rootStore.organizationsStore.currentOrganization.hasGerman) languages.push('German')
    if (this.rootStore.organizationsStore.currentOrganization.hasPseudo) languages.push('Pseudo')
    return languages
  }

  @action
  public setPhoneNumber(value: string): void {
    this.phone = value
  }

  @action
  public setVirtualListParent(parentEl: string) {
    this.virtualListParent = document.getElementById(parentEl)
  }

  @action
  public setPrimaryGroup(id: string) {
    this.primaryGroupId = id
  }

  @action
  public setPrimaryRole(id: string) {
    this.primaryRoleId = id
  }

  @action
  public setTitle(val: string) {
    this.title = val
  }

  // reactToPageNumberChanges = reaction(
  //   () => this.pagedData.pageNumber,
  //   (pageNumber) => (this.pagedDataCurrentPageNumber = pageNumber)
  // )

  // reactToFullAudienceChanges = reaction(
  //   () => this.fullAudience.length,
  //   () => {
  //     this.pagedData.setData(this.fullAudience)
  //     this.pagedData.setPageNumber(this.pagedDataCurrentPageNumber)

  //     if (
  //       this.pagedDataCurrentPageNumber > this.pagedData.pagesCount &&
  //       !this.pagedData.isFirstPage
  //     )
  //       this.pagedData.goPrevPage()
  //   }
  // )

  @action
  private validateRoles(roleIds: Array<string>): Array<string> {
    if (!Array.isArray(roleIds)) return []
    if (!this.rootStore || !this.rootStore.rolesStore) return []
    const validRoleIds = []
    roleIds.forEach((roleId) => {
      const foundRole = this.rootStore.rolesStore.roles.find((role) => role.objectId === roleId)
      if (foundRole) validRoleIds.push(roleId)
    })
    return validRoleIds
  }

  @action
  private validateGroups(groupIds: Array<string>): Array<string> {
    if (!Array.isArray(groupIds)) return []
    if (!this.rootStore || !this.rootStore.groupsStore) return []
    const validGroupIds = []
    groupIds.forEach((groupId) => {
      const foundGroup = this.rootStore.groupsStore.groups.find(
        (group) => group.objectId === groupId
      )
      if (foundGroup) validGroupIds.push(groupId)
    })
    return validGroupIds
  }

  @action
  public setAudienceFilter(val: string) {
    this.audienceFilter = val
  }

  @action
  public markSaveTried() {
    this.saveTried = true
  }

  public get isUserInfoValid() {
    if (
      !this.firstNameValid ||
      !this.lastNameValid ||
      !this.emailOrUsernameValid ||
      !this.passwordValid ||
      this.isEmailTaken
    )
      return false
    return true
  }

  public get firstNameValid() {
    if (!this.saveTried) return true
    if (!this.firstName) return false
    if (this.firstName.trim() === '') return false
    return true
  }

  public get lastNameValid() {
    if (!this.saveTried) return true
    if (!this.lastName) return false
    if (this.lastName.trim() === '') return false
    return true
  }

  public get emailOrUsernameValid() {
    if (!this.saveTried) return true
    if (this.emailValid || this.usernameValid) return true
    return false
  }

  public get emailValid() {
    if (!this.saveTried) return true
    let pattern = new RegExp(/[^\s@]+@[^\s@]+\.[^\s@]+/i)
    return pattern.test(this.email)
  }

  public get usernameValid() {
    if (!this.saveTried) return true
    if (!this.username) return false
    if (this.username.trim() === '') return false
    return true
  }

  @computed
  public get isEmailTaken(): boolean {
    return Boolean(
      this.rootStore.usersStore.orgUsers.filter(
        (user) =>
          user.email !== '' &&
          !user.isDisabled &&
          user.email === this.email &&
          user.objectId !== this.objectId
      )[0]
    )
  }

  @computed
  public get phoneValid() {
    if (!this.saveTried) return true
    if (this.phone && this.phone.trim() === '') return false
    if (this.phone && this.phone.length < 10) return false
    return true
  }

  public get passwordValid() {
    if (!this.saveTried) return true
    if (this.editingSelf) return true
    if (!this.password && !this.isNewUser) return true
    if (this.password && this.isPasswordStrong && !this.isNewUser) return true
    if (!this.isPasswordStrong) return false
    return true
  }

  @computed
  public get isPasswordStrong(): boolean {
    if (!this.password || this.password.trim() === '') return true
    if (this.meterVM.newPasswordStrengthScore < 5) return false
    return true
  }

  private trimSuperiors(superiorIds) {
    const result = []
    if (Array.isArray(superiorIds))
      superiorIds.forEach((id) => {
        if (this.rootStore.audienceMembersStore.allUsers.find((orgUser) => orgUser.objectId === id))
          result.push(id)
      })
    return result
  }

  private trimSubordinates(subordinateIds) {
    const result = []
    if (Array.isArray(subordinateIds))
      subordinateIds.forEach((id) => {
        if (this.rootStore.audienceMembersStore.allUsers.find((orgUser) => orgUser.objectId === id))
          result.push(id)
      })
    return result
  }

  @action public setEmployeeId(employeeId) {
    this.employeeId = employeeId
  }

  @action public setUsername(username) {
    this.username = username
  }

  @action public setJobNumber(jobNumber) {
    this.jobNumber = jobNumber
  }

  @action
  public setRoleStartDate(date) {
    this.roleStartDate = date
  }

  @action
  public setBirthDate(date) {
    this.birthDate = date
  }

  @computed
  public get isEditMode() {
    return Boolean(this.objectId)
  }

  @computed
  public get additionalAudienceObjects(): Array<any> {
    return this.additionalAudienceMembers.map((member) => {
      if (member.type === 'user') return this.rootStore.usersStore.getUser(member.objectId)
      if (member.type === 'group') return this.rootStore.groupsStore.getGroup(member.objectId)
      if (member.type === 'role') return this.rootStore.rolesStore.getRole(member.objectId)
    })
  }

  @computed
  public get availableSuperiors() {
    let availableSuperiors = this.rootStore.audienceMembersStore.allUsers.filter(
      (orgUser) =>
        orgUser.objectId !== this.objectId &&
        !this.subordinates.includes(orgUser.objectId) &&
        !this.superiors.includes(orgUser.objectId)
    )

    if (this.superiorFilter)
      availableSuperiors = availableSuperiors.filter(
        (orgUser) =>
          orgUser.name && orgUser.name.toLowerCase().includes(this.superiorFilter.toLowerCase())
      )

    return availableSuperiors.sort((a, b) => (a.firstName < b.firstName ? -1 : 0))
  }

  @computed
  public get selectedSuperiorUsers() {
    let superiorUsers = []
    for (let superiorId of this.superiors) {
      let foundUser = this.rootStore.audienceMembersStore.getUser(superiorId)
      if (foundUser) superiorUsers.push(foundUser)
    }
    return superiorUsers.sort((a, b) => (a.firstName < b.firstName ? -1 : 0))
  }

  @computed
  public get availableSubordinates() {
    let availableSubordinates = this.rootStore.audienceMembersStore.allUsers.filter(
      (orgUser) =>
        orgUser.objectId !== this.objectId &&
        !this.superiors.includes(orgUser.objectId) &&
        !this.subordinates.includes(orgUser.objectId)
    )

    if (this.subordinateFilter)
      availableSubordinates = availableSubordinates.filter(
        (orgUser) =>
          orgUser.name && orgUser.name.toLowerCase().includes(this.subordinateFilter.toLowerCase())
      )

    return availableSubordinates.sort((a, b) => (a.firstName < b.firstName ? -1 : 0))
  }

  @computed
  public get availableRoles() {
    let availableRoles = this.rootStore.rolesStore.roles.filter(
      (role) => role.objectId !== this.objectId && !this.roles.includes(role.objectId)
    )

    if (this.roleFilter)
      availableRoles = availableRoles.filter(
        (e) => e.name && e.name.toLocaleLowerCase().includes(this.roleFilter.toLowerCase())
      )

    return availableRoles.sort((a, b) => (a.name < b.name ? -1 : 0))
  }

  @computed
  public get availableGroups() {
    let availableGroups = this.rootStore.groupsStore.groups.filter(
      (group) => group.objectId !== this.objectId && !this.groups.includes(group.objectId)
    )

    if (this.groupFilter)
      availableGroups = availableGroups.filter(
        (e) => e.name && e.name.toLowerCase().includes(this.groupFilter.toLowerCase())
      )

    return availableGroups.sort((a, b) => (a.name < b.name ? -1 : 0))
  }

  @computed
  public get selectedSubordinateUsers() {
    let subordinateUsers = []
    for (let subordinateId of this.subordinates) {
      let foundUser = this.rootStore.audienceMembersStore.getUser(subordinateId)
      if (foundUser) {
        subordinateUsers.push(foundUser)
      }
    }
    return subordinateUsers.sort((a, b) => (a.firstName < b.firstName ? -1 : 0))
  }

  @computed
  public get selectedGroups() {
    let groups = []
    for (let groupId of this.groups) {
      let foundGroup = this.rootStore.groupsStore.getGroup(groupId)
      if (foundGroup) groups.push(foundGroup)
    }
    return groups
  }

  @computed
  public get selectedRoles() {
    let roles = []
    for (let roleId of this.roles) {
      let foundRole = this.rootStore.rolesStore.getRole(roleId)
      if (foundRole) roles.push(foundRole)
    }
    return roles
  }

  private doArraysHaveElementsInCommon(array1: Array<string>, array2: Array<string>): boolean {
    for (let i = 0; i < array1.length; i++) {
      if (array2.includes(array1[i])) return true
    }

    return false
  }

  private flattenAudienceUsers(audience) {
    const validOrgUsers = this.rootStore.usersStore.currentOrganizationUsers

    if (this.adminRole && this.adminRole.objectId && this.roles.includes(this.adminRole.objectId))
      return validOrgUsers.map((orgUser) => orgUser.userId)

    const audienceUserIds = []

    for (let i = 0; i < validOrgUsers.length; i++) {
      const orgUser = validOrgUsers[i]

      if (audience) {
        const { users, groups, roles } = audience

        if (users && users.includes(orgUser.userId)) {
          audienceUserIds.push(orgUser.userId)
          continue
        }

        if (groups && this.doArraysHaveElementsInCommon(groups, orgUser.groups)) {
          audienceUserIds.push(orgUser.userId)
          continue
        }

        if (roles && this.doArraysHaveElementsInCommon(roles, orgUser.roles)) {
          audienceUserIds.push(orgUser.userId)
          continue
        }
      }

      if (this.doArraysHaveElementsInCommon(this.groups, orgUser.groups)) {
        audienceUserIds.push(orgUser.userId)
        continue
      }

      if (this.doArraysHaveElementsInCommon(this.roles, orgUser.roles)) {
        audienceUserIds.push(orgUser.userId)
        continue
      }

      if (this.superiors.includes(orgUser.userId)) {
        audienceUserIds.push(orgUser.userId)
        continue
      }

      if (this.subordinates.includes(orgUser.userId)) {
        audienceUserIds.push(orgUser.userId)
        continue
      }
    }

    return audienceUserIds
  }

  @computed
  public get audienceRolesData() {
    let audienceRolesData = []

    for (let roleId of this.audienceRoles) {
      const foundRole = this.rootStore.rolesStore.roles.find((role) => role.objectId === roleId)

      if (foundRole)
        audienceRolesData.push({
          name: foundRole.name,
          objectId: foundRole.objectId,
          type: 'Role',
        })
    }

    if (this.audienceFilter)
      audienceRolesData = audienceRolesData.filter(
        (e) =>
          (e.name && e.name.toLowerCase().includes(this.audienceFilter.toLowerCase())) ||
          (e.type && e.type.toLowerCase().includes(this.audienceFilter.toLowerCase()))
      )

    return audienceRolesData
  }

  @computed
  public get audienceGroupsData() {
    let audienceGroupsData = []
    for (let groupId of this.audienceGroups) {
      const foundGroup = this.rootStore.groupsStore.groups.find(
        (group) => group.objectId === groupId
      )
      if (foundGroup)
        audienceGroupsData.push({
          name: foundGroup.name,
          objectId: foundGroup.objectId,
          type: 'Group',
        })
    }
    if (this.audienceFilter)
      audienceGroupsData = audienceGroupsData.filter(
        (e) =>
          (e.name && e.name.toLowerCase().includes(this.audienceFilter.toLowerCase())) ||
          (e.type && e.type.toLowerCase().includes(this.audienceFilter.toLowerCase()))
      )
    return audienceGroupsData
  }

  @computed
  public get audienceUsersData() {
    if (!this.rootStore) return
    let audienceUsersData = []
    const validOrgUsers = this.rootStore.usersStore.currentOrganizationUsers
    if (this.adminRole && this.adminRole.objectId && this.roles.includes(this.adminRole.objectId)) {
      audienceUsersData = validOrgUsers.map((orgUser) => ({
        objectId: orgUser.userId,
        name: orgUser.name,
        type: 'User',
      }))
    } else {
      this.audienceUsers.forEach((userId) => {
        const foundUser = validOrgUsers.find((orgUser) => orgUser.userId === userId)
        if (foundUser)
          audienceUsersData.push({
            objectId: foundUser.userId,
            name: foundUser.name,
            type: 'User',
          })
      })
    }
    if (this.audienceFilter)
      audienceUsersData = audienceUsersData.filter(
        (user) =>
          (typeof user.name === 'string' &&
            user.name.toLowerCase().includes(this.audienceFilter.toLowerCase())) ||
          (typeof user.type === 'string' &&
            user.type.toLowerCase().includes(this.audienceFilter.toLowerCase()))
      )
    return audienceUsersData
  }

  @action
  public removeItemFromAudience(objectId, type) {
    this.additionalAudienceMembers = this.additionalAudienceMembers.filter(
      (member) => member.objectId !== objectId
    )
  }

  @computed
  public get availableAudienceUsers() {
    return this.rootStore.usersStore.currentOrganizationUsers.filter(
      (orgUser) => !this.audienceUsers.includes(orgUser.userId)
    )
  }

  @computed
  public get availableAudienceRoles() {
    return this.rootStore.rolesStore.roles.filter(
      (role) => !this.audienceRoles.includes(role.objectId)
    )
  }

  @computed
  public get availableAudienceGroups() {
    return this.rootStore.groupsStore.groups.filter(
      (group) => !this.audienceGroups.includes(group.objectId)
    )
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    if (!this.firstNameValid) return false
    if (!this.lastNameValid) return false
    if (!this.emailOrUsernameValid) return false
    if (!this.passwordValid) return false
    if (!this.phoneValid) return false
    return true
  }

  @action
  public toggleShowPassword() {
    this.showPassword = !this.showPassword
  }

  @computed
  public get isPasswordMeterShown(): boolean {
    return this.meterVM.isPasswordMeterShown
  }

  @action
  public setPasswordMeterShown(isPasswordMeterShown: boolean) {
    if (this.password) return
    this.meterVM.setPasswordMeterShown(isPasswordMeterShown)
  }

  private reactToPrivilegeSetChanges() {
    const privilegeSets = [...this.privilegeSetsAddedManually]
    const privilegeSetsFromGroupsAndRoles = []
    if (this.groups && this.groups.length) {
      this.groups.forEach((groupId) => {
        const group =
          this.rootStore.groupsStore.groups && this.rootStore.groupsStore.groups.find
            ? this.rootStore.groupsStore.groups.find((group) => group.objectId === groupId)
            : null
        if (group && group.privilegeSets && group.privilegeSets.length) {
          privilegeSets.push(...group.privilegeSets)
          group.privilegeSets.forEach((privilegeSetObjectId) => {
            privilegeSetsFromGroupsAndRoles.push({
              privilegeSetObjectId,
              originType: 'group',
              originName: group.name,
            })
          })
        }
      })
    }

    if (this.roles && this.roles.length) {
      this.roles.forEach((roleId) => {
        const role =
          this.rootStore.rolesStore.roles && this.rootStore.rolesStore.roles.find
            ? this.rootStore.rolesStore.roles.find((group) => group.objectId === roleId)
            : null

        if (role && role.privilegeSets && role.privilegeSets.length) {
          privilegeSets.push(...role.privilegeSets)

          role.privilegeSets.forEach((privilegeSetObjectId) => {
            privilegeSetsFromGroupsAndRoles.push({
              privilegeSetObjectId,
              originType: 'role',
              originName: role.name,
            })
          })
        }
      })
    }

    this.privilegeSets = Array.from(new Set(privilegeSets)).sort((a, b) =>
      a.toLowerCase() > b.toLowerCase() ? 1 : -1
    )
    this.privilegeSetsFromGroupsAndRoles = privilegeSetsFromGroupsAndRoles
  }

  private privilegeSetsReaction = reaction(
    () => [this.privilegeSetsAddedManually.length, this.groups.length, this.roles.length],
    () => this.reactToPrivilegeSetChanges()
  )

  @action
  public toggleCheckedPrivSet(privilegeSetId) {
    if (this.privilegeSets.includes(privilegeSetId))
      this.privilegeSetsAddedManually = this.privilegeSetsAddedManually.filter(
        (id) => id !== privilegeSetId
      )
    else this.privilegeSetsAddedManually.push(privilegeSetId)
  }

  @action
  public toggleRoleChecked(roleId) {
    if (this.roles.includes(roleId)) {
      this.roles = this.roles.filter((id) => id !== roleId)
      this.audienceRoles = this.audienceRoles.filter((id) => id !== roleId)
      if (this.primaryRoleId === roleId) this.primaryRoleId = ''
    } else {
      this.roles.push(roleId)
      if (!this.audienceRoles.includes(roleId)) {
        this.audienceRoles.push(roleId)
        this.audienceUsers = this.flattenAudienceUsers({
          users: this.audienceUsers,
          groups: this.audienceGroups,
          roles: this.audienceRoles,
        })
      }
    }
  }

  @action
  public toggleGroupChecked(groupId) {
    if (this.groups.includes(groupId)) {
      this.groups = this.groups.filter((id) => id !== groupId)
      this.audienceGroups = this.audienceGroups.filter((id) => id !== groupId)
      if (this.primaryGroupId === groupId) this.primaryGroupId = ''
    } else {
      this.groups.push(groupId)
      if (!this.audienceGroups.includes(groupId)) {
        this.audienceGroups.push(groupId)
        this.audienceUsers = this.flattenAudienceUsers({
          users: this.audienceUsers,
          groups: this.audienceGroups,
          roles: this.audienceRoles,
        })
      }
    }
  }

  @action
  public toggleSuperiorChecked(superiorId) {
    if (this.superiors.includes(superiorId)) {
      this.superiors = this.superiors.filter((id) => id !== superiorId)
      this.audienceUsers = this.audienceUsers.filter((id) => id !== superiorId)
    } else {
      this.superiors.push(superiorId)
      if (!this.audienceUsers.includes(superiorId)) this.audienceUsers.push(superiorId)
    }
  }

  @action
  public toggleSubordinateChecked(subordinateId) {
    if (this.subordinates.includes(subordinateId)) {
      this.subordinates = this.subordinates.filter((id) => id !== subordinateId)
      this.audienceUsers = this.audienceUsers.filter((id) => id !== subordinateId)
    } else {
      this.subordinates.push(subordinateId)
      if (!this.audienceUsers.includes(subordinateId)) this.audienceUsers.push(subordinateId)
    }
  }

  @action toggleRolesCollapse() {
    this.showRoles = !this.showRoles
  }

  @action toggleGroupsCollapse() {
    this.showGroups = !this.showGroups
  }

  @action toggleSuperiorsCollapse() {
    this.showSuperiors = !this.showSuperiors
    this.superiorFilter = ''
  }

  @action toggleSubordinatesCollapse() {
    this.showSubordinates = !this.showSubordinates
    this.subordinateFilter = ''
  }

  @action
  public toggleBypassOIDCAuth() {
    this.bypassOIDCAuth = !this.bypassOIDCAuth
  }

  @action
  public cancelAudienceSelect() {
    this.addAudienceTabShown = false
    this.audienceListVM = new AudienceUsersAGGridVM(this.rootStore, this)
  }

  @action toggleAddAudience() {
    this.audienceUsersFromAddScreen = []
    this.audienceGroupsFromAddScreen = []
    this.audienceRolesFromAddScreen = []
    this.addAudienceTabShown = !this.addAudienceTabShown
    this.audienceMembersAdded = true
    this.audienceParticipantsSelectVM.setVisible()
    if (!this.addAudienceTabShown) {
      this.audienceListVM = new AudienceUsersAGGridVM(this.rootStore, this)
    }
  }

  @action
  public toggleNotifications(notificationType: string) {
    if (notificationType === 'email') {
      this.receiveEmails = !this.receiveEmails
    }
    if (notificationType === 'text') {
      this.receiveTextMessages = !this.receiveTextMessages
    }
    if (notificationType === 'push') {
      this.receivePushNotifications = !this.receivePushNotifications
    }
  }

  @action
  public handleAddToAdditionalAudienceMembers(objectId: string, type: AudienceMemberType) {
    const foundAudienceMember = Boolean(
      this.additionalAudienceMembers.find((member) => member.objectId === objectId)
    )
    if (foundAudienceMember) {
      this.additionalAudienceMembers = this.additionalAudienceMembers.filter(
        (member) => member.objectId !== objectId
      )
    } else {
      this.additionalAudienceMembers.push({ objectId: objectId, type: type })
    }
  }

  @action
  public isAudienceMemberChecked(objectId) {
    const foundAudienceMember = Boolean(
      this.additionalAudienceMembers.find((member) => member.objectId === objectId)
    )
    if (foundAudienceMember) return true
    return false
  }

  @action
  public addSelectedItemsToAudience() {
    let users = this.audienceUsers
    for (let role of this.audienceRolesFromAddScreen) {
      this.audienceRoles.push(role.objectId)
      const currentOrgUsersInRole = this.rootStore.audienceMembersStore.currentOrgUsersInRole(
        role.objectId
      )
      for (let orgUser of currentOrgUsersInRole) {
        users.push(orgUser.objectId)
      }
    }
    for (let group of this.audienceGroupsFromAddScreen) {
      this.audienceGroups.push(group.objectId)
      const currentOrgUsersInGroup = this.rootStore.audienceMembersStore.currentOrgUsersInGroup(
        group.objectId
      )
      for (let orgUser of currentOrgUsersInGroup) {
        users.push(orgUser.objectId)
      }
    }
    for (let user of this.audienceUsersFromAddScreen) {
      users.push(user.objectId)
    }
    this.audienceUsers = _.uniq(users)
    this.toggleAddAudience()
  }

  @action
  public setCurrentAudienceTab(idx) {
    this.currentAudienceTabIndex = idx
  }

  @action
  public setCurrentTab(idx) {
    this.currentTabIndex = idx
    if (idx === 2) {
      if (!this.audienceListVM)
        this.audienceListVM = new AudienceUsersAGGridVM(this.rootStore, this)
    }
  }

  @action
  public toggleMemberOpen(id) {
    const idx = this.openMembers.findIndex((e) => e === id)
    if (idx === -1) {
      this.openMembers.push(id)
      return
    }
    this.openMembers.splice(idx, 1)
  }

  public isMemberOpen(id) {
    return this.openMembers.findIndex((e) => e === id) !== -1
  }

  @computed get adminRole() {
    if (!this.rootStore) return null
    if (!this.rootStore.rolesStore) return null
    return this.rootStore.rolesStore.roles.find((role) => role.name === 'Administrator')
  }

  @computed
  public get isOrgAdmin() {
    return this.rootStore.appStore.isOrgAdmin
  }

  @action
  public async deleteOrgUser() {
    this.isProcessing = true
    const pSvc = new ParseService()
    await pSvc.deleteOrgUser(
      this.rootStore.appStore.currentOrgId,
      this.userId,
      true,
      this.adminRole && this.adminRole.objectId ? this.adminRole.objectId : undefined
    )
    this.isProcessing = false
    this.isOpen = false
    this.userJustDeleted = `${this.userId}-deleted`
    this.rootStore.usersStore.editDrawerShown = false
    if (this.userId === this.rootStore.appStore.currentUserId) {
      this.handleUserLogout()
    }
  }

  @action async archiveOrgUser() {
    this.isProcessing = true

    const pSvc = new ParseService()
    await pSvc.archiveOrgUser(
      this.rootStore.appStore.currentOrgId,
      this.userId,
      true,
      this.adminRole && this.adminRole.objectId ? this.adminRole.objectId : undefined
    )

    this.isProcessing = false
    this.isOpen = false
    this.rootStore.usersStore.editDrawerShown = false

    this.archived = true
    this.userJustArchived = `${this.userId}-archived`
  }

  @action async archiveUserForAllOrgs() {
    this.isProcessing = true

    await new ParseService().archiveUserForAllOrgs(this.userId)

    this.isProcessing = false
    this.isOpen = false
    this.rootStore.usersStore.editDrawerShown = false

    this.archived = true
    this.userJustArchived = `${this.userId}-archived`
  }

  @action async unArchiveOrgUser() {
    this.isProcessing = true

    const pSvc = new ParseService()
    await pSvc.unArchiveOrgUser(
      this.rootStore.appStore.currentOrgId,
      this.userId,
      true,
      this.adminRole && this.adminRole.objectId ? this.adminRole.objectId : undefined
    )

    this.isProcessing = false
    this.isOpen = false
    this.rootStore.usersStore.editDrawerShown = false

    this.archived = false
    this.userJustArchived = `${this.userId}-unarchived`
  }

  @computed
  public get canOpenUserSettings(): boolean {
    if (!this.rootStore.userStore.currentOrganization) return false
    if (!this.rootStore.organizationsStore.currentOrganization) return false
    return true
  }

  @action
  public unlockAccount = async (): Promise<void> => {
    const s = this.rootStore.localizationStore.lzStrings.userEdit
    try {
      const authSvc = new AuthenticationService()
      const result = await authSvc.unlockAccountAsAdmin(this.userId)
      if (result.success) {
        this.rootStore.organizationUsersStore.pwSnackbarVM.openSnackbar(
          `${s.success}: ${s.unlock_account_success}`
        )
      } else {
        this.rootStore.organizationUsersStore.pwSnackbarVM.openSnackbar(
          `${s.error}: ${s.unlock_account_error}`
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public editPassword() {
    this.rootStore.organizationUsersStore.loadPasswordEditVM(this.objectId)
  }

  @action
  public sendPasswordReset = async (): Promise<void> => {
    const s = this.rootStore.localizationStore.lzStrings.userEdit
    try {
      if (!this.email) {
        this.rootStore.organizationUsersStore.pwSnackbarVM.openSnackbar(
          `${s.error}: ${s.password_email_needs_email}`
        )
        return
      }
      const authSvc = new AuthenticationService()
      const result = await authSvc.sendPasswordResetAsAdmin(this.email)

      if (result === 'SUCCESS') {
        this.rootStore.organizationUsersStore.pwSnackbarVM.openSnackbar(
          `${s.success}: ${s.password_email_sent}`
        )
      } else if (result === 'UNKNOWN ERROR') {
        this.rootStore.organizationUsersStore.pwSnackbarVM.openSnackbar(
          `${s.error}: ${s.password_email_not_sent}`
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  public handleUserLogout() {
    this.rootStore.appStore.router.push('/auth/login')
    return Parse.User.logOut().then(() => {
      this.rootStore.appStore.clearData()
      window.location.reload()
    })
  }

  @action
  public openBecomeUser() {
    this.becomeDialogVM.show()
  }

  @action
  public openUserOneTimePasscode() {
    this.userOneTimeUsePasscodeDialogVM.show()
  }

  private collectUserDetails() {
    return {
      objectId: this.isNewUser ? undefined : this.objectId, // orgUser objectId
      userId: this.isNewUser ? undefined : this.userId, // _User objectId
      firstName: this.firstName,
      lastName: this.lastName,
      nickname: this.nickname,
      email: this.email,
      employeeId: this.employeeId,
      jobNumber: this.jobNumber,
      languagePreference: this.languagePreference,
      primaryRoleId: this.primaryRole ? this.primaryRole.objectId : '',
      primaryGroupId: this.primaryGroup ? this.primaryGroup.objectId : '',
      roles: this.roles.slice(),
      groups: this.groups.slice(),
      birthDate:
        this.birthDate && moment.isMoment(this.birthDate)
          ? this.birthDate.format()
          : this.birthDate,
      roleStartDate:
        this.roleStartDate && moment.isMoment(this.roleStartDate)
          ? this.roleStartDate.format()
          : this.roleStartDate,
      phone: this.phone,
      title: this.title,
      privilegeSets: this.privilegeSets.slice(),
      superiors: this.superiors.slice(),
      subordinates: this.subordinates.slice(),
      password: this.password,
      receiveEmails: this.receiveEmails,
      receivePushNotifications: this.receivePushNotifications,
      receiveTextMessages: this.receiveTextMessages,
      bypassOIDCAuth: this.bypassOIDCAuth,
      isDisabled: this.isDisabled,
      disabled: false,
      additionalAudienceMembers: this.audienceParticipantsSelectVM.participantsToDTO(),
      isArchived: this.isArchived,
      archivedDate: this.archivedDate,
      username: this.username ? this.username.toLowerCase() : this.email,
      tableauLicenseType: this.tableauLicenseType,
      tableauLicensePermanent: this.tableauLicensePermanent,
      isHiddenInOrg: this.isHiddenInOrg,
    } as unknown as IUserDetailsDTO
  }

  @action
  public async save() {
    this.markSaveTried()
    if (!this.isValid) return
    const userDetails = this.collectUserDetails()
    const orgId = this.rootStore.appStore.currentOrgId
    this.isProcessing = true
    if (!this.isNewUser) {
      try {
        const svc = new OrganizationUsersService(this.rootStore)
        const result = await svc.updateUser(orgId, this.userId, userDetails)
        if (!result.success) throw result.errorMessage
        if (this.hiddenStatusChanged) {
          this.userJustWasHidden = `${this.userId}-hidden`
        }
        this.rootStore.organizationUsersStore.loadEditVM(
          result.orgUserSaveResult.organizationUser.objectId
        )
      } catch (error) {
        this.handleUpdateFailed('This username/email already exists', userDetails.objectId)
        console.error(error)
        Sentry.captureException({
          message: 'Error creating a new user.',
          orgId,
          error,
        })
        return false
      }
    } else {
      try {
        const svc = new OrganizationUsersService(this.rootStore)
        const result = await svc.addUser(orgId, userDetails)
        if (!result.success) throw result.errorMessage
        this.newUserJustSaved = `${result.orgUserSaveResult.organizationUser.objectId}-saved`
        this.rootStore.organizationUsersStore.loadEditVM(
          result.orgUserSaveResult.organizationUser.objectId
        )
      } catch (error) {
        console.error(error)
        Sentry.captureException({
          message: 'Error creating a new user.',
          orgId,
          error,
        })
        return false
      }
    }
  }

  @computed
  public get isSystemAdmin(): boolean {
    return this.rootStore.appStore.isSystemAdmin
  }

  @computed
  public get showSetUserOneTimeUsePasscode(): boolean {
    if (this.rootStore.appStore.isSystemAdmin && !this.isNewUser) return true
    return false
  }

  @computed
  public get showLogInAsUserButton(): boolean {
    if (this.isNewUser) return false
    if (!this.organizationUser) return false
    if (this.rootStore.appStore.currentUserId === this.userId) return false
    if (!this.rootStore.organizationsStore.currentOrganization.canImpersonateUsers) return false
    if (this.rootStore.appStore.isSystemAdmin) return true
    if (!this.rootStore.appStore.canImpersonateUsers) return false
    if (this.rootStore.appStore.isOrgAdmin) return true
    return false
  }

  @computed
  public get shouldShowBypassOIDCAuth(): boolean {
    if (!this.rootStore.userStore.currentOrganization) return false
    if (!this.rootStore.appStore.isOrgAdmin) return false
    return Boolean(this.rootStore.userStore.currentOrganization.requireOIDCAuth)
  }

  @action
  public toggleDrawer(): void {
    this.isOpen = !this.isOpen
  }

  @action
  public hideDrawer() {
    this.isOpen = false
  }

  @computed
  public get profileImageText(): string {
    if (this.iconURL) return 'Change Photo'
    return 'Upload Photo'
  }

  @action
  public async nullProfilePhoto() {
    this.hideDeleteConfirmDialog()
    this.profilePicVM.isProcessing = true
    this.rootStore.userStore.avatarMenuVM.setRefreshing(true)

    const svc = new CMSAvatarDeleteService(this.rootStore)
    const req: ICMSAvatarDeleteRequest = {
      userId: this.rootStore.appStore.currentUserId,
    }
    await svc.deleteAvatar(req)

    this.rootStore.userStore.avatarMenuVM.setRefreshing(false)
    this.rootStore.usersStore.loadUsers(this.rootStore.appStore.currentUserId)
    this.profilePicVM.isProcessing = false
  }

  @computed
  public get editingSelf(): boolean {
    if (this.rootStore.appStore.currentUserId === this.userId) return true
    return false
  }

  @computed
  public get emailAddressRequired(): boolean {
    if (!this.rootStore.organizationsStore.currentOrganization.allowSeparateUsernames) return true
    if (
      this.rootStore.organizationsStore.currentOrganization.allowSeparateUsernames &&
      !this.username
    )
      return true
    return false
  }

  @computed
  public get usernameRequired(): boolean {
    if (this.rootStore.organizationsStore.currentOrganization.allowSeparateUsernames && !this.email)
      return true
    return false
  }

  @computed
  public get isTableauExplorer(): boolean {
    return this.tableauLicenseType === 'SiteAdministratorExplorer'
  }

  @action
  public toggleTableauExplorer() {
    if (this.isTableauExplorer) {
      this.tableauLicenseType = ''
      if (!this.rootStore.userStore) return
      this.rootStore.userStore.user.setTableauLicenseType('')
      return
    }
    this.tableauLicenseType = 'SiteAdministratorExplorer'
    if (!this.rootStore.userStore) return
    this.rootStore.userStore.user.setTableauLicenseType('SiteAdministratorExplorer')
  }

  @action
  public toggleTableauLicensePermanent() {
    this.tableauLicensePermanent = !this.tableauLicensePermanent
  }

  @action
  public async sendWelcomeEmail() {
    const orgId = this.rootStore.appStore.currentOrgId
    const selectedParticipant = [this.userId]
    this.isProcessing = true
    try {
      const req: IOrganizationUsersBulkRequest = {
        orgId: orgId,
        selectedUserIds: selectedParticipant,
        allSelected: false,
        unselectedUserIds: [],
      }
      const authSvc = new AuthenticationService()
      const result = await authSvc.sendWelcomeEmail(req)
      if (result.success === false) {
        console.log(result.errorMessage)
      }
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public async expirePassword() {
    console.log('hit')
    const orgId = this.rootStore.appStore.currentOrgId
    const selectedParticipant = [this.userId]
    this.isProcessing = true
    try {
      const req: IOrganizationUsersBulkRequest = {
        orgId: orgId,
        selectedUserIds: selectedParticipant,
        allSelected: false,
        unselectedUserIds: [],
      }
      const authSvc = new AuthenticationService()
      const result = await authSvc.expirePassword(req)
      if (result.success === false) {
        console.log(result.errorMessage)
      }
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public showDeleteConfirmDialog() {
    this.isConfimDeleteDialogOpen = true
  }

  @action
  public hideDeleteConfirmDialog() {
    this.isConfimDeleteDialogOpen = false
  }
}
