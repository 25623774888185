import React, { FC } from 'react'
import Dropzone from 'react-dropzone'
import { inject, observer } from 'mobx-react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import EmailIcon from '@material-ui/icons/Email'
import TextsmsIcon from '@material-ui/icons/Textsms'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CheckIcon from '@material-ui/icons/Check'
import env from '../../../../env'
import './UserInfoTab.scss'
import { OrganizationsStore } from '../../../organizations/store/OrganizationsStore'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ProfilePictureDialog from './ProfilePictureDialog'
import {
  MenuItem,
  Select,
  Typography,
  Checkbox,
  Tooltip,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core'
import { OrganizationUsersStore } from '../../store/OrganizationUsersStore'
import UserBecomeDialog from './UserBecomeDialog'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import { Role } from '../../../roles/aggregate/Role'
import { Group } from '../../../groups/aggregate/Group'
import UserOneTimeUsePasscodeDialog from './UserOneTimeUsePasscodeDialog'
import LocalizationStore from '../../../localization/LocalizationStore'
import InfoTooltip from '../../../shared/InfoTooltip'
import MuiConfirmPrompt from '../../../shared/MuiConfirmPrompt'

interface Props {
  organizationUsersStore?: OrganizationUsersStore
  organizationsStore?: OrganizationsStore
  localizationStore?: LocalizationStore
}

const UserInfoTab: FC<Props> = ({
  organizationUsersStore,
  organizationsStore,
  localizationStore,
}) => {
  const { editVM: vm } = organizationUsersStore
  const profilePicVM = vm.profilePicVM
  const { userEditor: lz } = localizationStore.lzStrings

  const renderConfirmDeleteDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={`Are you sure you want to delete this profile avatar? This action cannot be undone!`}
        onClose={() => vm.hideDeleteConfirmDialog()}
        open={vm.isConfimDeleteDialogOpen}
        onConfirm={() => vm.nullProfilePhoto()}
        title={`Warning`}
      />
    )
  }

  const renderDiscardAvatarIcon = () => {
    if (!vm.iconURL) return
    return (
      <Tooltip title='Discard Profile Image' placement='right-end' enterDelay={500}>
        <div id='AvatarDiscard' onClick={() => vm.showDeleteConfirmDialog()}>
          <DeleteIcon className='trash-icon' />
        </div>
      </Tooltip>
    )
  }

  const renderSpinner = () => {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const buildAvatarUrl = (
    userId: string = 'U',
    firstName: string = 'U',
    lastName: string = 'N'
  ) => {
    return `${
      env.var.REACT_APP_CMS_API_URL
    }/api/avatar/${userId}?firstName=${firstName}&lastName=${lastName}&t=${Date.now()}`
  }

  const renderAvatarPicture = () => {
    if (profilePicVM.isProcessing) return renderSpinner()
    if (!vm.iconURL) return renderSpinner()

    const url = buildAvatarUrl(vm.userId, vm.firstName, vm.lastName)

    if (vm.userId) return <Avatar className='user-avatar' src={url} />
    else return <AccountCircleIcon className='no-avatar' />
  }

  const renderUserPhoto = () => {
    if (vm.isNewUser) return null
    return (
      <div id='userPhoto'>
        <Grid container alignItems='center' justifyContent='center'>
          {renderAvatarPicture()}
        </Grid>
        <Grid container alignItems='center' justifyContent='center'>
          <Dropzone
            accept={'image/jpeg, image/png'}
            onDrop={(file) => profilePicVM.handleDrop(file)}
            multiple={false}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <>
                <div {...getRootProps()} className='UserAvatar'>
                  <Button variant='text'>{vm.profileImageText}</Button>
                  <input {...getInputProps()} />
                </div>
                {renderDiscardAvatarIcon()}
              </>
            )}
          </Dropzone>
        </Grid>
      </div>
    )
  }

  const renderBasicInfo = () => {
    return (
      <div id='userBasicInfo'>
        <h6>{lz.basic_info}</h6>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <InputLabel required htmlFor='first-name'>
              {lz.first_name}
            </InputLabel>
            <TextField
              error={!vm.firstNameValid}
              value={vm.firstName || ''}
              onChange={(e) => (vm.firstName = e.target.value)}
              fullWidth
              variant='outlined'
              id='first-name'
              disabled={!vm.isEditable && !vm.isUserOwned}
            />
          </Grid>
          <Grid item sm={6}>
            <InputLabel required htmlFor='first-name'>
              {lz.last_name}
            </InputLabel>
            <TextField
              error={!vm.lastNameValid}
              value={vm.lastName || ''}
              onChange={(e) => (vm.lastName = e.target.value)}
              fullWidth
              variant='outlined'
              id='last-name'
              disabled={!vm.isEditable && !vm.isUserOwned}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12}>
            <InputLabel htmlFor='title'>{lz.title}</InputLabel>
            <TextField
              value={vm.title}
              onChange={(e) => vm.setTitle(e.target.value)}
              fullWidth
              variant='outlined'
              id='title'
              disabled={!vm.isEditable && !vm.isUserOwned}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className='birthday-input' item md={12} sm={12}>
            <InputLabel htmlFor='birthdate'>{lz.birthdate}</InputLabel>
            <KeyboardDatePicker
              fullWidth
              inputVariant='outlined'
              format='MM/dd/yyyy'
              margin='none'
              id='birthdate'
              maxDate={new Date()}
              disabled={!vm.isEditable && !vm.isUserOwned}
              value={vm.birthDate ? vm.birthDate : null}
              onChange={(e) => vm.setBirthDate(String(e))}
              KeyboardButtonProps={{
                'aria-label': 'change date',
                className: 'birthdate-button',
                edge: 'end',
              }}
            />
          </Grid>
          {renderJobNumber()}
          {renderEmployeeId()}
          <Grid item md={6} sm={6}>
            <InputLabel htmlFor='primary-role'>{lz.primary_role}</InputLabel>
            <Autocomplete
              disabled={!vm.isEditable && !vm.isUserOwned}
              options={vm.primaryRoleOptions}
              getOptionLabel={(e) => e.name}
              id='primary-role'
              value={vm.primaryRole ? vm.primaryRole : null}
              onChange={(e, val: Role) => vm.setPrimaryRole(val && val.objectId)}
              renderInput={(params) => (
                <TextField {...params} variant='outlined' fullWidth margin='none' />
              )}
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <InputLabel htmlFor='primary-group'>{lz.primary_group}</InputLabel>
            <Autocomplete
              disabled={!vm.isEditable && !vm.isUserOwned}
              options={vm.primaryGroupOptions}
              getOptionLabel={(e) => e.name}
              id='primary-group'
              value={vm.primaryGroup ? vm.primaryGroup : null}
              onChange={(e, val: Group) => vm.setPrimaryGroup(val && val.objectId)}
              renderInput={(params) => (
                <TextField {...params} variant='outlined' fullWidth margin='none' />
              )}
            />
          </Grid>
          {renderRoleStartDate()}
          {renderLanguageInfo()}
          {renderUsername()}
          {renderBypassOIDCAuth()}
        </Grid>
      </div>
    )
  }

  const renderRoleStartDate = () => {
    return (
      <Grid className='roleStartDate-input' item md={6} sm={6}>
        <InputLabel htmlFor='roleStart'>{lz.role_start_date}</InputLabel>
        <KeyboardDatePicker
          fullWidth
          disabled={!vm.isEditable && !vm.isUserOwned}
          inputVariant='outlined'
          format='MM/dd/yyyy'
          margin='none'
          id='roleStart'
          value={vm.roleStartDate ? vm.roleStartDate : null}
          onChange={(e) => vm.setRoleStartDate(String(e))}
          KeyboardButtonProps={{
            'aria-label': 'change date',
            className: 'birthdate-button',
            edge: 'end',
          }}
        />
      </Grid>
    )
  }

  const renderEmployeeId = () => {
    if (!organizationsStore.currentOrganization.hasEmployeeId) return null
    return (
      <Grid item md={6} sm={6}>
        <InputLabel htmlFor='employee-id'>{lz.employee_id}</InputLabel>
        <TextField
          disabled={!vm.isEditable && !vm.isUserOwned}
          fullWidth
          id='employee-id'
          variant='outlined'
          type='text'
          value={vm.employeeId || ''}
          onChange={(e) => vm.setEmployeeId(e.target.value)}
        />
      </Grid>
    )
  }

  const renderUsername = () => {
    if (!organizationsStore.currentOrganization.allowSeparateUsernames) return null
    return (
      <Grid item md={6} sm={6}>
        <InputLabel required={vm.usernameRequired} htmlFor='employee-id'>
          Username
        </InputLabel>
        <TextField
          disabled={!vm.isEditable && !vm.isUserOwned}
          error={!vm.emailOrUsernameValid && vm.usernameRequired}
          fullWidth
          id='username'
          variant='outlined'
          value={vm.username || ''}
          onChange={(e) => vm.setUsername(e.target.value)}
        />
      </Grid>
    )
  }

  const renderJobNumber = () => {
    if (!organizationsStore.currentOrganization.hasJobNumber) return null
    return (
      <Grid item md={6} sm={6}>
        <InputLabel htmlFor='job-number'>{lz.job_number}</InputLabel>
        <TextField
          disabled={!vm.isEditable && !vm.isUserOwned}
          fullWidth
          id='job-number'
          variant='outlined'
          type='text'
          value={vm.jobNumber || ''}
          onChange={(e) => vm.setJobNumber(e.target.value)}
        />
      </Grid>
    )
  }

  const renderContactInfo = () => {
    return (
      <div id='userContactInfo'>
        <h6>{lz.contact}</h6>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12}>
            <InputLabel required={vm.emailAddressRequired} htmlFor='email'>
              {lz.email_address}
            </InputLabel>
            <TextField
              className='email-input'
              error={!vm.emailOrUsernameValid && vm.emailAddressRequired}
              value={vm.email || ''}
              onChange={(e) => (vm.email = e.target.value)}
              fullWidth
              variant='outlined'
              id='email'
              helperText={vm.isEmailTaken ? 'That email address is already taken.' : ''}
              disabled={!vm.isEditable && !vm.isUserOwned}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12}>
            <InputLabel htmlFor='phone'>{lz.phone_number}</InputLabel>
            <TextField
              disabled={!vm.isEditable && !vm.isUserOwned}
              value={vm.phone}
              onChange={(e) => vm.setPhoneNumber(e.target.value)}
              fullWidth
              variant='outlined'
              id='phone'
              helperText={vm.phoneValid ? '' : 'Phone number not valid'}
            />
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderLanguageInfo = () => {
    if (vm.languageOptions.length === 1) return null
    return (
      <Grid item xs={6}>
        <InputLabel htmlFor='language'>{lz.language}</InputLabel>
        <Select
          variant='outlined'
          fullWidth
          value={vm.languagePreference}
          onChange={(e) => vm.setLanguagePreference(e.target.value)}
        >
          {vm.languageOptions.map((option, index) => {
            return (
              <MenuItem
                key={index}
                className={vm.languagePreference === option ? 'language-selected' : 'language'}
                value={option}
              >
                {option}
              </MenuItem>
            )
          })}
        </Select>
      </Grid>
    )
  }

  const renderBypassOIDCAuth = () => {
    if (!vm.shouldShowBypassOIDCAuth) return undefined
    return (
      <Grid item xs={6}>
        <InputLabel htmlFor='language'>{lz.bypass_oidc_auth}</InputLabel>
        <FormControlLabel
          control={
            <Checkbox checked={vm.bypassOIDCAuth} onChange={() => vm.toggleBypassOIDCAuth()} />
          }
          label={
            <Typography variant='h6' className='alarm-text'>
              {lz.yes}
            </Typography>
          }
        />
      </Grid>
    )
  }

  const renderCheckMark = (checked: boolean) => {
    if (!checked) return
    return <CheckIcon className='notification-check' />
  }

  const renderNotificationsTitle = () => {
    if (vm.isNewUser) {
      return (
        <Grid container>
          <h6 className='user-note-tool'>{lz.notifications}</h6>
          <InfoTooltip message='Save new user to edit these settings.' />
        </Grid>
      )
    } else {
      return <h6>{lz.notifications}</h6>
    }
  }

  const getNotificationButtonClassValue = (isChecked) =>
    isChecked ? 'tab-btn-selected border-status-initiated' : 'tab-btn-unselected'

  const renderNotifications = () => {
    return (
      <div id='UserNotificationInfo'>
        {renderNotificationsTitle()}
        <div className='notification-buttons'>
          <Button
            className={getNotificationButtonClassValue(vm.receiveEmails)}
            onClick={() => vm.toggleNotifications('email')}
          >
            <EmailIcon />
            Email
            {renderCheckMark(vm.receiveEmails)}
          </Button>
          <Button
            className={getNotificationButtonClassValue(vm.receiveTextMessages)}
            onClick={() => vm.toggleNotifications('text')}
          >
            <TextsmsIcon />
            Text
            {renderCheckMark(vm.receiveTextMessages)}
          </Button>
          <Button
            className={getNotificationButtonClassValue(vm.receivePushNotifications)}
            onClick={() => vm.toggleNotifications('push')}
          >
            <PhoneAndroidIcon />
            Push
            {renderCheckMark(vm.receivePushNotifications)}
          </Button>
        </div>
      </div>
    )
  }

  const renderWelcomeEmailStatus = () => {
    if (!vm.isEditable) return null
    return (
      <div className={'welcome-status'}>
        <h6>{'Welcome Email Status'}</h6>
        <Typography className='welcome-status-text'>{vm.welcomeStatus}</Typography>
      </div>
    )
  }

  const renderLogInAsUser = () => {
    if (!vm.showLogInAsUserButton) return
    return (
      <Grid item className='button-space'>
        <Button
          className={vm.receiveEmails ? 'Mui-selected' : ''}
          variant='outlined'
          startIcon={<EmailIcon />}
          onClick={() => vm.openBecomeUser()}
        >
          {lz.log_in_as_user}
        </Button>
      </Grid>
    )
  }

  const renderSetUserOneTimeUsePasscode = () => {
    if (!vm.showSetUserOneTimeUsePasscode) return
    return (
      <Grid item>
        <Button
          className={vm.receiveEmails ? 'Mui-selected' : ''}
          variant='outlined'
          startIcon={<EmailIcon />}
          onClick={() => vm.openUserOneTimePasscode()}
        >
          {lz.get_mobile_passcode}
        </Button>
      </Grid>
    )
  }

  const renderSystemAdminOnlyFields = () => {
    return (
      <Grid item xs={6}>
        <InputLabel htmlFor='language'>Tableau Explorer</InputLabel>
        <FormControlLabel
          control={
            <Checkbox checked={vm.isTableauExplorer} onChange={() => vm.toggleTableauExplorer()} />
          }
          label={
            <Typography variant='h6' className='alarm-text'>
              {lz.yes}
            </Typography>
          }
        />
        <InputLabel htmlFor='language'>Keeps Tableau License</InputLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!vm.tableauLicensePermanent}
              onChange={() => vm.toggleTableauLicensePermanent()}
            />
          }
          label={
            <Typography variant='h6' className='alarm-text'>
              {lz.yes}
            </Typography>
          }
        />
        <InputLabel htmlFor='language'>Hidden</InputLabel>
        <FormControlLabel
          control={<Checkbox checked={vm.isHiddenInOrg} onChange={() => vm.toggleHidden()} />}
          label={
            <Typography variant='h6' className='alarm-text'>
              {lz.yes}
            </Typography>
          }
        />
      </Grid>
    )
  }

  const renderHidden = () => {
    return <Grid item xs={6}></Grid>
  }

  const renderSysAdminContent = () => {
    if (!vm.isSystemAdmin) return

    return (
      <>
        <br />
        <div>
          <h6>{lz.system_admin}</h6>
          <Grid container direction='row'>
            {renderActions()}
            {renderSystemAdminOnlyFields()}
            {renderHidden()}
          </Grid>
        </div>
      </>
    )
  }

  const renderActions = () => {
    if (!vm.isSystemAdmin) return
    if (vm.isNewUser) return
    return (
      <>
        <Grid item xs={6}>
          <InputLabel htmlFor='language'>Actions</InputLabel>
          {renderLogInAsUser()}
          {renderSetUserOneTimeUsePasscode()}
        </Grid>
      </>
    )
  }

  return (
    <div id='UserInfoTab'>
      <ProfilePictureDialog />
      <UserBecomeDialog />
      <UserOneTimeUsePasscodeDialog />
      <form autoComplete='off' noValidate>
        {renderUserPhoto()}
        {renderBasicInfo()}
        {renderContactInfo()}
        {renderNotifications()}
        {renderWelcomeEmailStatus()}
        {renderSysAdminContent()}
        {renderConfirmDeleteDialog()}
      </form>
    </div>
  )
}

export default inject(
  'organizationUsersStore',
  'organizationsStore',
  'localizationStore'
)(observer(UserInfoTab))
