import { observable, action, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { MediaItem } from '../aggregate/MediaItem'
import { MediaItemDrawerVM } from '../view-models/drawer/MediaItemDrawerVM'
import { MediaLibraryVM } from '../view-models/media-library/MediaLibraryVM'
import { IMediaItemDTO } from '../dtos/IMediaItemDTO'
import { DataStore } from '../../shared/data/DataStore'

export class MediaItemsStore extends DataStore<MediaItem, IMediaItemDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, MediaItem, 'mediaItems', [
      'organizationId',
      'name',
      'type',
      'createdAt',
      'size',
      'fileUrl',
      'videoThumbnail',
    ])
  }

  @observable public mediaItemDrawerVM: MediaItemDrawerVM = null
  @observable public mediaLibraryVM: MediaLibraryVM
  @observable public isMediaItemDrawerOpen: boolean
  @observable public loaded: boolean = false

  @computed
  public get mediaItems(): MediaItem[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.loaded = true
  }

  @computed
  public get currentOrgMediaItems(): Array<MediaItem> {
    return this.mediaItems
  }

  @action
  public openDrawer(id?: string) {
    let mediaItem: MediaItem
    if (id) {
      mediaItem = this.getMediaItem(id)
      this.mediaItemDrawerVM = new MediaItemDrawerVM(mediaItem.clone(), this.rootStore)
      this.isMediaItemDrawerOpen = true
    } else {
      this.mediaItemDrawerVM = new MediaItemDrawerVM(
        MediaItem.create(this.rootStore.appStore.currentOrgId),
        this.rootStore
      )
      this.isMediaItemDrawerOpen = true
    }
  }

  @action
  public closeDrawer() {
    this.isMediaItemDrawerOpen = false
    if (
      this.rootStore.surveysStore.viewModels.editVM &&
      this.rootStore.surveysStore.viewModels.editVM.currentQuestion.mediaUploadPickerVM
    ) {
      this.rootStore.surveysStore.viewModels.editVM.currentQuestion.mediaUploadPickerVM.showMediaUploadPicker =
        true
    }
    if (
      this.rootStore.questionsStore.editVM &&
      this.rootStore.questionsStore.editVM.mediaUploadPickerVM
    ) {
      this.rootStore.questionsStore.editVM.mediaUploadPickerVM.showMediaUploadPicker = true
    }
  }

  @action
  public getMediaItem(id: string): MediaItem {
    return this.mediaItems.find((e) => e.objectId === id)
  }
}
