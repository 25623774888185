import { action, observable, computed } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import RGLItem from '../../aggregate/RGLItem'
import { DashboardType } from '../../types/DashboardType'
import SharedSnackbarVM from '../../view-models/SharedSnackbarVM'
import { WidgetContainerVM } from '../../view-models/WidgetContainerVM'
import UserDashboard from '../aggregate/UserDashboard'
import { UserDashboardsUpdateService } from '../service/UserDashboardsUpdateService'

export default class UserDashboardVM {
  rootStore: RootStore
  private saving: any

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable public showMenu: boolean = false
  @observable public showSharedDashboardsDrawer: boolean = false
  @observable public showFavoriteSnackbar: boolean = false
  @observable public sharedSnackbarVM: SharedSnackbarVM = null
  @observable public userDashboard: UserDashboard = null

  public loadUserDashboard(userDashboard: UserDashboard) {
    this.userDashboard = userDashboard
    if (!this.userDashboard.type || this.userDashboard.type === 'custom')
      this.setlastVisitedDateTime()
  }

  public clearUserDashboard() {
    this.showMenu = false
    this.userDashboard = null
  }

  public get objectId(): string {
    if (!this.userDashboard) return ''
    return this.userDashboard.objectId
  }

  public get organizationId(): string {
    if (!this.userDashboard) return ''
    return this.userDashboard.organizationId
  }

  public get name(): string {
    if (!this.userDashboard) return ''
    return this.userDashboard.name
  }

  public get type(): DashboardType {
    if (!this.userDashboard) return 'custom'
    return this.userDashboard.type
  }

  @computed
  public get layout(): RGLItem[] {
    if (!this.userDashboard) return []
    return this.userDashboard.lgLayout
  }

  @computed
  public get dashboardId(): string {
    if (!this.userDashboard) return ''
    return this.userDashboard.dashboardId
  }

  private hasPrivilege(type) {
    if (
      type === 'ManageSurveysWidget' ||
      type === 'SurveyTemplatesWidget' ||
      type === 'QuestionsCatalogWidget' ||
      type === 'QuestionCatalogWidget'
    ) {
      return this.rootStore.appStore.canSurvey
    }
    return true
  }

  public widgetIsDecomissioned(type: string) {
    if (
      type === 'ContactsWidget' ||
      type === 'ContactListWidget' ||
      type === 'ClientsWidget' ||
      type === 'ClientManagementWidget' ||
      type === 'TaskTypesWidget' ||
      type === 'MediaItemsListWidget' ||
      type === 'SurveyTypesWidget'
    ) {
      return true
    }
    return false
  }

  @computed
  public get widgets(): WidgetContainerVM[] {
    if (!this.userDashboard) return []
    return this.userDashboard.lgLayout.reduce((acc, current) => {
      if (this.hasPrivilege(current.type) && !this.widgetIsDecomissioned(current.type)) {
        acc.push(new WidgetContainerVM(this.rootStore, current, this.dashboardId))
      }
      return acc
    }, [])
  }

  public get isEditable(): boolean {
    if (!this.userDashboard) return true
    if (this.userDashboard.ownerUserId !== this.rootStore.appStore.currentUserId) return false
    return true
  }

  public get isFavorite(): boolean {
    if (!this.userDashboard) return false
    return this.userDashboard.isFavorite
  }

  public addTask() {
    this.rootStore.tasksStore.createNewTask()
    this.rootStore.tasksStore.openDrawer()
  }

  public addSurvey() {
    return this.rootStore.appStore.router.push('/surveys/create')
  }

  public addWorksheet() {
    this.rootStore.appStore.router.push('/worksheets/edit/new')
  }

  @computed
  public get impactTab(): number {
    const type = this.rootStore.userDashboardsStore.viewModels.typeFromRoute
    if (type === 'impactGoals') return 0
    if (type === 'impactTrainingPlans') return 1
    if (type === 'impactCatalog') return 2
  }

  public setSelectedImpactRoute(index: number) {
    if (index === 0) this.rootStore.appStore.router.push('/dashboard/impact/goals')
    if (index === 1) this.rootStore.appStore.router.push('/dashboard/impact/trainings')
    if (index === 2) this.rootStore.appStore.router.push('/dashboard/impact/catalog')
  }

  @computed
  public get tenantAdminTab(): number {
    const type = this.rootStore.userDashboardsStore.viewModels.typeFromRoute
    if (type === 'tenantAdminOrg') return 0
    if (type === 'tenantAdminConfig') return 1
    if (type === 'tenantAdminSurvey') return 2
  }

  public setSelectedTenantAdminRoute(index: number) {
    if (index === 0) this.rootStore.appStore.router.push('/dashboard/tenantAdmin/org')
    if (index === 1) this.rootStore.appStore.router.push('/dashboard/tenantAdmin/config')
    if (index === 2) {
      this.rootStore.surveysStore.viewModels.clearEditVM()
      this.rootStore.appStore.router.push('/dashboard/tenantAdmin/survey')
    }
  }

  public showSharedWithDrawer() {
    this.rootStore.dashboardsStore.sharedWithVM.loadDashboard(this.dashboardId)
  }

  public editDashboard() {
    if (this.type === 'custom')
      this.rootStore.appStore.router.push(`/dashboard/userDashboard/edit/${this.dashboardId}`)
    if (this.type === 'analysis')
      this.rootStore.appStore.router.push(`/dashboard/worksheets/edit/${this.dashboardId}`)
    if (this.type === 'tasks')
      this.rootStore.appStore.router.push(`/dashboard/tasks/edit/${this.dashboardId}`)
    if (this.type === 'surveys')
      this.rootStore.appStore.router.push(`/dashboard/surveys/edit/${this.dashboardId}`)
    if (this.type === 'impactGoals')
      this.rootStore.appStore.router.push(`/dashboard/impact/goals/edit/${this.dashboardId}`)
    if (this.type === 'impactTrainingPlans')
      this.rootStore.appStore.router.push(`/dashboard/impact/trainings/edit/${this.dashboardId}`)
    if (this.type === 'impactCatalog')
      this.rootStore.appStore.router.push(`/dashboard/impact/catalog/edit/${this.dashboardId}`)
    if (this.type === 'tenantAdminOrg')
      this.rootStore.appStore.router.push(`/dashboard/tenantAdmin/org/edit/${this.dashboardId}`)
    if (this.type === 'tenantAdminConfig')
      this.rootStore.appStore.router.push(`/dashboard/tenantAdmin/config/edit/${this.dashboardId}`)
    if (this.type === 'tenantAdminSurvey')
      this.rootStore.appStore.router.push(`/dashboard/tenantAdmin/survey/edit/${this.dashboardId}`)
  }

  public openWidgetDrawer() {
    this.editDashboard()
    this.lazyOpenWidgetDrawer()
  }

  public lazyOpenWidgetDrawer(attempts: number = 0) {
    if (attempts === 10) return null
    const editVM = this.rootStore.dashboardsStore.editVM
    if (!editVM) return setTimeout(() => this.lazyOpenWidgetDrawer(++attempts), 500)
    if (!editVM.dashboard) return setTimeout(() => this.lazyOpenWidgetDrawer(++attempts), 500)
    editVM.toggleShowWidgetDrawer()
  }

  public createDashboard() {
    this.rootStore.appStore.router.push(`/dashboard/userDashboard/edit/new`)
  }

  public cloneDashboard() {
    this.rootStore.appStore.router.push(`/dashboard/userDashboard/clone/${this.dashboardId}`)
  }

  @action
  public toggleShowMenu() {
    this.showMenu = !this.showMenu
  }

  public async toggleIsFavorite() {
    if (this.saving) clearTimeout(this.saving)
    this.saving = setTimeout(async () => {
      this.userDashboard.toggleIsFavorite()
      const favoriteObj: UserDashboard = {
        objectId: this.objectId,
        organizationId: this.organizationId,
        isFavorite: this.isFavorite,
      } as any
      const svc = new UserDashboardsUpdateService()
      await svc.updateUserDashboard(favoriteObj)
      this.toggleShowFavoriteSnackbar()
    }, 500)
  }

  public toggleShowFavoriteSnackbar() {
    this.showFavoriteSnackbar = !this.showFavoriteSnackbar
  }

  public toggleShowSharedDashboardsDrawer() {
    this.showSharedDashboardsDrawer = !this.showSharedDashboardsDrawer
  }

  public goToSharedDashboard(dashboard) {
    this.toggleShowSharedDashboardsDrawer()
    this.rootStore.userDashboardsStore.viewModels.setSelectedDashboardRoute(dashboard)
  }

  public getUserDashboardOwnerName(userId: string) {
    const foundUser = this.rootStore.audienceMembersStore.getUser(userId)
    if (foundUser) return foundUser.name
    return userId
  }

  public loadSharedSnackbar(shared: boolean) {
    this.sharedSnackbarVM = new SharedSnackbarVM(this.rootStore, shared)
  }

  public closeSharedSnackbar() {
    this.sharedSnackbarVM = null
  }

  public async setlastVisitedDateTime() {
    const visitedObj: UserDashboard = {
      objectId: this.objectId,
      organizationId: this.organizationId,
      lastVisitedDateTime: new Date(),
    } as any
    // this.userDashboard.setLastVisitedDateTime()
    const svc = new UserDashboardsUpdateService()
    await svc.updateUserDashboard(visitedObj)
  }

  @action
  public navigateToManageUserOnboarding() {
    this.rootStore.appStore.router.push('/dashboard/tenantAdmin/users')
  }

  @computed
  public get canManageSurveyTemplates() {
    if (this.rootStore.appStore.isOrgAdmin) return true
    return this.rootStore.appStore.canManageSurveyTemplates
  }
}
