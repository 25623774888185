import rootStore from '../stores/RootStore'

export class DashboardService {
  getSystemAdminDashboard() {
    const layout = [
      { i: '0', x: 0, y: 0, h: 2, w: 2, minH: 2, minW: 2, type: 'SystemAdminsListWidget' },
      { i: '5', x: 2, y: 0, h: 2, w: 2, minH: 2, minW: 2, type: 'OrganizationsSAListWidget' },
      { i: '10', x: 0, y: 1, h: 2, w: 2, minH: 2, minW: 2, type: 'NotificationsWidget' },
    ]

    const systemAdminDashboard = {
      widgets: [
        { type: 'SystemAdminsListWidget' },
        { type: 'OrganizationsSAListWidget' },
        { type: 'NotificationsWidget' },
      ],
      layouts: {
        sm: layout,
        md: layout,
        lg: layout,
      },
      layout: layout,
    }

    return systemAdminDashboard
  }

  getWorksheetsDashboard() {
    const layout = [{ i: '0', x: 0, y: 0, h: 3, w: 4, type: 'WorksheetsListWidget' }]

    const systemAdminDashboard = {
      widgets: [{ type: 'WorksheetsListWidget' }],
      layouts: {
        sm: layout,
        md: layout,
        lg: layout,
      },
      layout: layout,
    }

    return systemAdminDashboard
  }

  getTasksDashboard() {
    const layout = [
      { i: '0', x: 0, y: 1, h: 2, w: 2, type: 'TasksManagementWidget' },
      { i: '1', x: 0, y: 0, h: 2, w: 2, type: 'PendingUserTasksWidget' },
      { i: '2', x: 2, y: 0, h: 2, w: 2, type: 'CompletedUserTasksWidget' },
    ]

    const tasksDashboard = {
      widgets: [
        { type: 'TasksManagementWidget' },
        { type: 'PendingUserTasksWidget' },
        { type: 'CompletedUserTasksWidget' },
      ],
      layouts: {
        sm: layout,
        md: layout,
        lg: layout,
      },
      layout: layout,
    }

    return tasksDashboard
  }

  getImpactCatalogDashboard() {
    const layout = [
      { i: '1', x: 0, y: 2, h: 2, w: 2, type: 'GoalsCatalogWidget' },
      { i: '2', x: 0, y: 0, h: 2, w: 2, type: 'CatalogPlansWidget' },
      { i: '3', x: 2, y: 0, h: 2, w: 2, type: 'CatalogItemsWidget' },
    ]

    const impactCatalogDashboard = {
      widgets: [{ type: 'CatalogWidget' }, { type: 'GoalsCatalogWidget' }],
      layouts: {
        sm: layout,
        md: layout,
        lg: layout,
      },
      layout: layout,
    }

    return impactCatalogDashboard
  }

  getGoalsDashboard() {
    const layout = [
      { i: '0', x: 2, y: 0, h: 2, w: 2, type: 'ManageGoalsWidget' },
      { i: '1', x: 0, y: 0, h: 4, w: 2, type: 'MyGoalsWidget' },
    ]

    const goalsDashboard = {
      widgets: [{ type: 'ManageGoalsWidget' }, { type: 'MyGoalsWidget' }],
      layouts: {
        sm: layout,
        md: layout,
        lg: layout,
      },
      layout: layout,
    }

    return goalsDashboard
  }

  getTrainingsDashboard() {
    const layout = [
      { i: '1', x: 0, y: 0, h: 4, w: 2, type: 'MyTrainingPlansWidget' },
      { i: '2', x: 2, y: 0, h: 2, w: 2, type: 'ManageTrainingPlansWidget' },
      { i: '3', x: 2, y: 2, h: 2, w: 2, type: 'TrainerWidget' },
    ]

    const trainingsDashboard = {
      widgets: [
        { type: 'MyTrainingPlansWidget' },
        { type: 'ManageTrainingPlansWidget' },
        { type: 'TrainerWidget' },
      ],
      layouts: {
        sm: layout,
        md: layout,
        lg: layout,
      },
      layout: layout,
    }

    return trainingsDashboard
  }

  getTenantAdminOrgDashboard() {
    const layout = [
      { i: '0', x: 0, y: 0, h: 2, w: 1, type: 'OrganizationGeneralWidget' },
      { i: '1', x: 1, y: 0, h: 2, w: 3, type: 'OrgUsersTableWidget' },
      { i: '2', x: 0, y: 1, h: 2, w: 2, type: 'ManageGroupsAndRolesWidget' },
      { i: '3', x: 2, y: 1, h: 2, w: 2, type: 'PrivilegeSetsWidget' },
    ]

    let tenantAdminOrgDashboard = {
      widgets: [
        { type: 'OrganizationGeneralWidget' },
        { type: 'OrgUsersTableWidget' },
        { type: 'PrivilegeSetsWidget' },
        { type: 'ManageGroupsAndRolesWidget' },
      ],
      layouts: {
        sm: layout,
        md: layout,
        lg: layout,
      },
      layout: layout,
    }
    return tenantAdminOrgDashboard
  }

  getTenantAdminConfigDashboard() {
    const layout = [
      { i: '0', x: 0, y: 0, h: 2, w: 2, type: 'NotificationsWidget' },
      { i: '1', x: 2, y: 0, h: 2, w: 2, type: 'CategoriesListWidget' },
      { i: '2', x: 0, y: 2, h: 2, w: 2, type: 'CalendarManagementWidget' },
      { i: '3', x: 4, y: 2, h: 2, w: 1, type: 'CustomCollectionsListWidget' },
      { i: '4', x: 3, y: 0, h: 1, w: 1, type: 'ConsentWidget' },
    ]

    let tenantAdminConfigDashboard = {
      widgets: [
        { type: 'NotificationsWidget' },
        { type: 'CategoriesListWidget' },
        { type: 'CalendarManagementWidget' },
        { type: 'CustomCollectionsListWidget' },
        { type: 'ConsentWidget' },
      ],
      layouts: {
        sm: layout,
        md: layout,
        lg: layout,
      },
      layout: layout,
    }
    return tenantAdminConfigDashboard
  }

  getTenantAdminSurveyDashboard() {
    const layout = [
      { i: '1', x: 2, y: 0, h: 2, w: 2, type: 'QuestionsCatalogWidget' },
      // { i: '3', x: 0, y: 0, h: 2, w: 2, type: 'SurveyAutomationsWidget'}
      { i: '2', x: 2, y: 2, h: 2, w: 2, type: 'SurveyTagsWidget' },
    ]

    let tenantAdminSurveyDashboard = {
      widgets: [
        { type: 'QuestionsCatalogWidget' },
        { type: 'SurveyTagsWidget' },
        // {type: 'SurveyAutomationsWidget'}
      ],
      layouts: {
        sm: layout,
        md: layout,
        lg: layout,
      },
      layout: layout,
    }
    return tenantAdminSurveyDashboard
  }

  getSurveysDashboard() {
    const layout = [
      { i: '1', x: 0, y: 0, h: 2, w: 2, type: 'PendingUserSurveysWidget' },
      { i: '2', x: 2, y: 0, h: 2, w: 2, type: 'ManageSurveysWidget' },
      { i: '3', x: 0, y: 2, h: 2, w: 2, type: 'SurveyResponsesWidget' },
      { i: '4', x: 2, y: 2, h: 2, w: 2, type: 'SurveyTemplatesWidget' },
      { i: '5', x: 0, y: 4, h: 2, w: 2, type: 'QuestionCatalogWidget' },
    ]

    let systemAdminDashboard = {
      widgets: [
        { type: 'PendingUserSurveysWidget' },
        { type: 'ManageSurveysWidget' },
        { type: 'SurveyResponsesWidget' },
        { type: 'SurveyTemplatesWidget' },
        { type: 'QuestionCatalogWidget' },
      ],
      layouts: {
        sm: layout,
        md: layout,
        lg: layout,
      },
      layout: layout,
    }
    return systemAdminDashboard
  }
}
