import { RootStore } from '../../stores/RootStore'
import { PrivilegeSetsStore } from '../store/PrivilegeSetsStore'
import { computed, observable, action } from 'mobx'
import { GridApi, GridReadyEvent, ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import PrivilegeSet from '../aggregate/PrivilegeSet'
import { PrivilegeSetRowVM } from './PrivilegeSetRowVM'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../shared/nameOf'

export class PrivilegeSetsWidgetVM extends AGGridVM {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.groupsTable.name,
        field: nameOf<PrivilegeSetRowVM, string>((e) => e.name),
        sort: 'asc',
      },
      {
        headerName: s.groupsTable.description,
        field: nameOf<PrivilegeSetRowVM, string>((e) => e.description),
        valueFormatter: ({ value }) => {
          if (!value) return '-'
          return value
        },
      },
    ]
  }

  @computed
  public get rows(): PrivilegeSetRowVM[] {
    return this.rootStore.privilegeSetsStore.currentOrgPrivilegeSets.map(
      (row) => new PrivilegeSetRowVM(this.rootStore, row, this)
    )
  }

  public get shouldRender(): boolean {
    if (!this.rootStore.privilegeSetsStore.loaded) return false
    return true
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
      paginationAutoPageSize: false,
      paginationPageSize: 8,
    }
  }

  public addPrivilegeSet() {
    this.rootStore.privilegeSetsStore.setAddNewPrivilegeSet()
    this.rootStore.privilegeSetsStore.showDrawer = true
  }

  public rowClicked(e: RowClickedEvent) {
    const row: PrivilegeSetRowVM = e.data
    if (!row) return
    row.openPrivilegeSet()
  }
}
