import { action, observable } from 'mobx'
import { ParseService } from '../../../services/ParseService'
import { RootStore } from '../../../stores/RootStore'
import { OrganizationUserEditVM } from './OrganizationUserEditVM'
import { CMSAvatarUploadService } from '../../../cms-avatars/service/CMSAvatarUploadService'
import { ICMSAvatarUploadRequest } from '../../../cms-avatars/interfaces/ICMSAvatarUploadRequest'

export default class ProfilePictureVM {
  private rootStore: RootStore
  private editVM: OrganizationUserEditVM

  constructor(rootStore: RootStore, editVM: OrganizationUserEditVM) {
    this.rootStore = rootStore
    this.editVM = editVM
  }

  @observable public file: File = null
  @observable public imgRef: any
  @observable public showProfilePictureDialog: boolean = false
  @observable public upImg: any
  @observable public crop: any
  @observable public isProcessing: boolean = false

  @action
  public setImgRef(img) {
    this.imgRef = img
  }

  @action
  public setCrop(c) {
    this.crop = c
  }

  @action
  private setUpImg(result) {
    this.upImg = result
  }

  @action
  public toggleShowProfilePictureDialog() {
    this.showProfilePictureDialog = !this.showProfilePictureDialog
  }

  @action
  public async handleDrop(file) {
    this.file = file[0]
    const reader = new FileReader()
    reader.addEventListener('load', () => this.setUpImg(reader.result))
    reader.readAsDataURL(file[0])
    this.setCrop({ unit: '%', width: 50, height: 50 })
    this.toggleShowProfilePictureDialog()
  }

  private async getCroppedImg(image: HTMLImageElement, crop) {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )
    const dataUrl = canvas.toDataURL('image/jpeg')
    return dataUrl
  }

  @action
  public cancel() {
    this.showProfilePictureDialog = false
  }

  @action
  private async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
    const res: Response = await fetch(dataUrl)
    const blob: Blob = await res.blob()
    return new File([blob], fileName, { type: 'image/png' })
  }

  @action
  public async save() {
    this.isProcessing = true
    this.rootStore.userStore.avatarMenuVM.setRefreshing(true)
    const fileName = new Date().getTime().toString() + '.png'

    try {
      let dataUrl = await this.getCroppedImg(this.imgRef, this.crop)
      const file = await this.dataUrlToFile(dataUrl, fileName)

      const svc = new CMSAvatarUploadService(this.rootStore)
      const req: ICMSAvatarUploadRequest = {
        userId: this.editVM.userId,
        customFile: file,
      }
      const res = await svc.uploadAvatar(req)
      if (res && res.data.avatar) {
        const iconURL = this.editVM.iconURL
        this.editVM.iconURL = iconURL
      }
    } catch (error) {
      console.error('Error saving profile picture:', error)
    } finally {
      this.isProcessing = false
      this.rootStore.userStore.avatarMenuVM.setRefreshing(false)
    }

    this.toggleShowProfilePictureDialog()
    this.rootStore.userStore.avatarMenuVM.setRefreshing(false)
    this.rootStore.usersStore.loadUsers(this.rootStore.appStore.currentUserId)
    this.isProcessing = false
  }

  private saveAvatar = async (iconURL: string) => {
    if (this.editVM.userId) {
      await new ParseService().saveUserAvatar(
        this.rootStore.appStore.currentOrgId,
        iconURL,
        this.editVM.userId
      )
    }
  }
}
