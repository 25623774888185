import { RootStore } from '../../stores/RootStore'
import { observable, action, computed } from 'mobx'
import { Client } from '../aggregate/Client'
import { DataStore } from '../../shared/data/DataStore'
import { ClientEditVM } from './../view-models/ClientEditVM'
import { IClientDTO } from './../dtos/IClientDTO'

export class ClientsStore extends DataStore<Client, IClientDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Client, 'clients', ['organizationId', 'name', 'description', 'isDefault'])
  }

  @observable public clientEditVM: ClientEditVM = null
  @observable public isWidgetDrawerOpen: boolean = false
  @observable public showDeleteDialog: boolean = false
  @observable public loaded: boolean = false

  @computed
  public get clients() {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.loaded = true
  }

  @action
  public setSelectedClientToEdit(client) {
    this.clientEditVM = new ClientEditVM(this.rootStore, client)
    this.toggleWidgetDrawer()
  }

  @action
  public addNewClient() {
    this.clientEditVM = new ClientEditVM(this.rootStore)
    this.toggleWidgetDrawer()
  }

  @action
  public toggleWidgetDrawer() {
    this.isWidgetDrawerOpen = !this.isWidgetDrawerOpen
  }

  @action
  public closeWidgetDrawer() {
    this.isWidgetDrawerOpen = false
  }

  @action
  public reloadEditVM(client) {
    this.clientEditVM = new ClientEditVM(this.rootStore, client)
  }

  @action
  public toggleDeleteDialog() {
    this.showDeleteDialog = !this.showDeleteDialog
  }

  public deleteClient(objectId) {
    this.clients.splice(this.getClientIndex(objectId), 1)
  }

  private getClientIndex(objectId): number {
    return this.clients.findIndex((e) => e.objectId === objectId)
  }

  public getClient(objectId): Client {
    return this.clients.find((e) => e.objectId === objectId)
  }

  public getClientName(objectId): string {
    const client = this.clients.find((e) => e.objectId === objectId)
    return client.name
  }
}
