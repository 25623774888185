import React from 'react'
import { observer, inject } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import Grid from '@material-ui/core/Grid'
import { SurveyEditTabsEnum } from '../../../types/SurveyEditTabsEnum'
import './DetailsTabPanel.scss'
import TextField from '@material-ui/core/TextField'
import CategoryPicker from '../../../../shared/category-picker/CategoryPicker'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import InfoTooltip from '../../../../shared/InfoTooltip'
import SurveyIntroduction from './SurveyIntroduction'
import SurveyType from '../../../../survey-types/store/aggregate/SurveyType'
import SurveyTag from '../../../../survey-tags/aggregate/SurveyTag'
import { Tab, Tabs } from '@material-ui/core'
import SurveyCompletionContent from './SurveyCompletionContent'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const DetailsTabPanel: React.FC<Props> = ({ surveysStore, localizationStore, labelsStore }) => {
  const vm = surveysStore.viewModels.editVM
  if (!vm) return null
  const { surveys: strings } = localizationStore.lzStrings

  if (vm.currentTabIndex !== SurveyEditTabsEnum.DETAILS) return null

  const renderOverallCategory = () => {
    if (vm.isSurveyTemplateEdit && vm.survey.organizationId === 'DEFAULT') return null
    return (
      <Grid item xs={12}>
        <h6 className='includes-info'>
          {strings.overall +
            ' ' +
            labelsStore.getLabelByLanguageAndFor('pulse') +
            ' ' +
            labelsStore.getLabelByLanguageAndFor('category')}
          <InfoTooltip message={strings.main_category_info} />
        </h6>
        <div className={vm.isReadOnly ? 'input-div' : 'input-div'}>
          <CategoryPicker
            disabled={vm.isReadOnly}
            containerStyle={{}}
            onApply={(categoryId) => vm.setMainCategory(categoryId)}
            vm={vm.categoryPickerVM}
          />
        </div>
      </Grid>
    )
  }

  const renderDetailsRow = () => {
    return (
      <Grid item xs={12} sm={6} className='details-tab-panel-grid-left'>
        <Grid item xs={12}>
          <h6>{labelsStore.getLabelByLanguageAndFor('pulse') + ' ' + strings.title}</h6>
          <div className='input-div'>
            <TextField
              value={vm.name}
              onChange={(e) => vm.setName(e.target.value)}
              placeholder={'(Untitled ' + labelsStore.getLabelByLanguageAndFor('pulse') + ')'}
              className={vm.isNameValid ? 'toolbar-input error' : 'toolbar-input error'}
              disabled={vm.isSaving || vm.isReadOnly}
              error={!vm.isNameValid}
              variant='outlined'
            />
          </div>
        </Grid>
        {renderOverallCategory()}
        <Grid item xs={12}>
          <h6>{labelsStore.getLabelByLanguageAndFor('pulse') + ' ' + strings.type}</h6>
          <div className='input-div'>
            <Autocomplete
              disabled={vm.isReadOnly}
              className='survey-type toolbar-input'
              options={vm.surveyTypes}
              getOptionLabel={(type) => type.name}
              value={vm.surveyType || { name: '' }}
              onChange={(e, type: SurveyType) =>
                vm.setSurveyType(type && type.objectId ? type.objectId : '')
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    strings.select +
                    ' ' +
                    labelsStore.getLabelByLanguageAndFor('pulse') +
                    ' ' +
                    strings.type
                  }
                  variant='outlined'
                  className='survey-type-input'
                />
              )}
            />
          </div>
        </Grid>
        {vm.allowSurveyTags ? (
          <Grid item xs={12}>
            <h6>
              {' '}
              {labelsStore.getLabelByLanguageAndFor('pulse') +
                ' ' +
                labelsStore.getLabelByLanguageAndFor('tag')}
            </h6>
            <div className='input-div'>
              <Autocomplete
                disabled={vm.isReadOnly}
                className='survey-type toolbar-input'
                options={vm.surveyTags}
                getOptionLabel={(tag) => tag.name}
                value={vm.surveyTag || { name: '' }}
                onChange={(e, tag: SurveyTag) =>
                  vm.setSurveyTag(tag && tag.objectId ? tag.objectId : '')
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      'Select ' +
                      labelsStore.getLabelByLanguageAndFor('pulse') +
                      ' ' +
                      labelsStore.getLabelByLanguageAndFor('tag')
                    }
                    variant='outlined'
                    className='survey-type-input'
                  />
                )}
              />
            </div>
          </Grid>
        ) : null}
      </Grid>
    )
  }

  const renderTabs = () => {
    return (
      <Grid item xs={12} sm={6} className='details-tabs-panel-right'>
        <Grid item xs={12}>
          <Tabs
            orientation='horizontal'
            variant='standard'
            value={vm.surveyDetailsTabIndex}
            onChange={(e, index) => {
              vm.setSurveyDetailsTabIndex(index)
            }}
            className='survey-details-tabs'
          >
            <Tab className='survey-details-tab' label='Introduction' {...a11yProps(0)} />
            <Tab className='survey-details-tab' label='Completed Content' {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={vm.surveyDetailsTabIndex} index={0}>
            <SurveyIntroduction vm={vm.surveyIntroductionVM} key={vm.surveyDetailsTabIndex} />
          </TabPanel>
          <TabPanel value={vm.surveyDetailsTabIndex} index={1}>
            <SurveyCompletionContent
              vm={vm.surveyCompletedContentVM}
              key={vm.surveyDetailsTabIndex}
            />
          </TabPanel>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container id='DetailsTabPanel'>
      {renderDetailsRow()}
      {renderTabs()}
    </Grid>
  )
}

const TabPanel = (props) => {
  const { children, value, index, ...rest } = props
  return (
    <div
      id={`survey-details-tabpanel-${index}`}
      aria-labelledby={`survey-details-tabpanel-${index}`}
      hidden={value !== index}
      {...rest}
      className='details-tab-panel'
    >
      {children}
    </div>
  )
}

const a11yProps = (index) => {
  return {
    id: `survey-details-tab-${index}`,
    'aria-controls': `survey-details-tab-${index}`,
  }
}

export default inject('surveysStore', 'localizationStore', 'labelsStore')(observer(DetailsTabPanel))
