import React from 'react'
import { inject, observer } from 'mobx-react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import WidgetContainer from './WidgetContainer'
import { UserDashboardsStore } from '../../UserDashboardsStore'

interface Props {
  RGLprops: any
  rowHeight: any
  minRowHeight: any
  userDashboardsStore?: UserDashboardsStore
}

const RGL = WidthProvider(Responsive)

const UserDashboardContent: React.FC<Props> = ({
  rowHeight,
  minRowHeight,
  RGLprops,
  userDashboardsStore,
}) => {
  const vm = userDashboardsStore.viewModels.userDashboardVM
  const layout = vm.widgets.slice()
  const layouts = {
    xxs: layout,
    xs: layout,
    sm: layout,
    md: layout,
    lg: layout,
  }
  RGLprops.layouts = layouts

  const renderWidgets = () => {
    let widgets = []
    layout.forEach((e) => {
      widgets.push(
        <div className='widget-container' key={e.index}>
          <WidgetContainer widget={e} />
        </div>
      )
    })
    return widgets
  }

  return (
    <div>
      {layout && (
        <RGL {...RGLprops} rowHeight={Math.max(rowHeight, minRowHeight)}>
          {renderWidgets()}
        </RGL>
      )}
    </div>
  )
}

export default inject('userDashboardsStore')(observer(UserDashboardContent))
