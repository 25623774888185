import { RootStore } from '../../stores/RootStore'
import { observable, action, computed } from 'mobx'
import { ContactEditVM } from './../view-models/ContactEditVM'
import { Contact } from '../aggregate/Contact'
import { DataStore } from '../../shared/data/DataStore'
import { IContactDTO } from './../dtos/IContactDTO'
import { ParseService } from '../../services/ParseService'

export class ContactsStore extends DataStore<Contact, IContactDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Contact, 'contacts', [
      'organizationId',
      'name',
      'clientId',
      'title',
      'email',
      'isDefault',
      'type',
    ])
  }

  @observable public contactEditVM: ContactEditVM = null
  @observable public isWidgetDrawerOpen: boolean = false
  @observable public loaded: boolean = false

  @computed
  public get contacts() {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.loaded = true
  }

  @action
  public getContactsByClient(clientId): Array<any> {
    return this.contacts.filter((e) => e.clientId === clientId && e.clientId !== null)
  }

  @action
  public setSelectedContactToEdit(contact) {
    this.contactEditVM = new ContactEditVM(this.rootStore, contact)
    this.toggleWidgetDrawer()
  }

  @action
  public addNewContact(client?) {
    this.contactEditVM = new ContactEditVM(this.rootStore, null, client)
    this.toggleWidgetDrawer()
  }

  @action
  public reloadEditVM(contact) {
    this.contactEditVM = new ContactEditVM(this.rootStore, contact)
  }

  @action
  public toggleWidgetDrawer() {
    this.isWidgetDrawerOpen = !this.isWidgetDrawerOpen
  }

  @action
  public clearEditVM() {
    this.contactEditVM = new ContactEditVM(this.rootStore)
  }

  @action
  public removeClientFromContact(contactId) {
    this.contacts.forEach((contact) => {
      if (contact.objectId === contactId) {
        contact.clientId = ''
      }
    })
  }

  public deleteContact(objectId) {
    this.contacts.splice(this.getContactIndex(objectId), 1)
  }

  private getContactIndex(objectId): number {
    return this.contacts.findIndex((e) => e.objectId === objectId)
  }

  public getContact(objectId): Contact {
    return this.contacts.find((e) => e.objectId === objectId)
  }

  @action
  public getContactByEmail(email) {
    return this.contacts.find((e) => e.email === email)
  }

  @computed
  public get otherContacts(): Array<any> {
    return this.contacts.filter((e) => e.clientId === '')
  }

  public async deleteContactById(id) {
    const svc = new ParseService()
    await svc.deleteContact(this.rootStore.appStore.currentOrgId, id)
  }
}
