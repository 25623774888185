import { computed, observable, action } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { ColDef, RowClickedEvent, GridOptions } from 'ag-grid-community'
import { nameOf } from '../../../shared/nameOf'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'
import { EventTypeRowVM } from './EventTypeRowVM'
import { LocationRowVM } from './LocationRowVM'
import { ResourceRowVM } from './ResourceRowVM'

export class CalendarManagementWidgetVM extends AGGridVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
  }

  @observable public tabIndex: number = 0
  @observable public editScreen: boolean = false

  @action
  public setTabIndex(index: number) {
    this.tabIndex = index
    this.columnApi.setColumnsVisible(['description'], true)
    if (index === 1) {
      this.columnApi.setColumnsVisible(['description'], false)
    }
    this.gridApi.sizeColumnsToFit()
    this.gridApi.paginationGoToPage(0)
    this.gridApi.onFilterChanged()
  }

  @action
  public getRowNodeId(row: EventTypeRowVM | LocationRowVM | ResourceRowVM): string {
    return row.objectId
  }

  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: 'Name',
        field: nameOf<EventTypeRowVM | LocationRowVM | ResourceRowVM, string>((e) => e.name),
      },
      {
        headerName: 'Description',
        field: nameOf<EventTypeRowVM | LocationRowVM | ResourceRowVM, string>((e) => e.description),
        hide: Boolean(this.tabIndex === 1),
        colId: 'description',
      },
    ]
  }

  @computed
  public get hasSurveyPrivilege(): boolean {
    return this.rootStore.appStore.canSurvey
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.rootStore.eventTypesStore.loaded) return false
    if (!this.rootStore.locationsStore.loaded) return false
    if (!this.rootStore.resourcesStore.loaded) return false
    return true
  }

  @computed
  public get rows() {
    return [...this.eventTypeRows, ...this.locationRows, ...this.resourceRows]
  }

  private get eventTypeRows() {
    return this.rootStore.eventTypesStore.currentOrgEventTypes.map(
      (e) => new EventTypeRowVM(this.rootStore, e, this)
    )
  }

  private get locationRows() {
    return this.rootStore.locationsStore.currentOrgLocations.map(
      (e) => new LocationRowVM(this.rootStore, e, this)
    )
  }

  private get resourceRows() {
    return this.rootStore.resourcesStore.currentOrgResources.map(
      (e) => new ResourceRowVM(this.rootStore, e, this)
    )
  }

  @action
  protected isExternalFilterPresent(): boolean {
    return Boolean(this.tabIndex !== 3)
  }

  @action
  protected doesExternalFilterPass(node) {
    switch (this.tabIndex) {
      case 0:
        return Boolean(node.data.type === 'eventType')
      case 1:
        return Boolean(node.data.type === 'location')
      case 2:
        return Boolean(node.data.type === 'resource')
      default:
        return true
    }
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
      paginationAutoPageSize: false,
      paginationPageSize: 8,
    }
  }

  public onAddClick(value) {
    if (value === 0) {
      this.rootStore.eventTypesStore.setAddNewEventType()
      this.rootStore.eventTypesStore.showDrawer = true
    }
    if (value === 1) {
      this.rootStore.locationsStore.setAddNewLocation()
      this.rootStore.locationsStore.editForm.editAddress = true
      this.rootStore.locationsStore.showDrawer = true
    }
    if (value === 2) {
      this.rootStore.resourcesStore.setAddNewResource()
      this.rootStore.resourcesStore.showDrawer = true
    }
  }

  public rowClicked(e: RowClickedEvent) {
    const row: EventTypeRowVM | LocationRowVM | ResourceRowVM = e.data
    if (!row) return
    row.editAggregate()
  }
}
