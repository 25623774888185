import { observable, action, computed, runInAction, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Survey } from '../aggregate/Survey'
import { SurveyEditVM } from '../view-models/SurveyEditVM'
import { ManageSurveysWidgetVM } from '../view-models/manage-surveys-widget/ManageSurveysWidgetVM'
import { ParticipantType } from '../../participants-select/types/ParticipantType'
import { SurveysStore } from '../store/SurveysStore'
import { ISurveysFindRequest } from '../interfaces/ISurveysFindRequest'
import { SurveyTemplatesService } from '../../survey-templates/services/SurveyTemplatesService'
import { SurveyTemplate } from '../../survey-templates/aggregates/SurveyTemplate'
import { deserialize } from 'serializr'

export class SurveysViewModelsService {
  private rootStore: RootStore
  private surveysStore: SurveysStore

  constructor(rootStore: RootStore, surveysStore: SurveysStore) {
    this.rootStore = rootStore
    this.surveysStore = surveysStore
  }

  @observable public editVM: SurveyEditVM = null
  @observable public manageSurveysFilter = null

  public listenForCurrentOrgAndListRecordsLoaded(id: string) {
    const disposer = reaction(
      () => this.rootStore.organizationsStore.currentOrganization,
      () => {
        this.loadSurveyEditVM(id)
        disposer()
      }
    )
  }

  @action
  public async loadSystemSurveyTemplateEditVM(
    id: string,
    orgId: string = 'DEFAULT',
    folderId: string
  ): Promise<SurveyEditVM> {
    if (id === 'empty') return (this.editVM = null)
    if (id === 'new') {
      const survey = Survey.create(orgId, this.rootStore.appStore.currentUserId)
      this.editVM = new SurveyEditVM(this.rootStore, survey)
      if (folderId && folderId !== 'no-folder') {
        this.editVM.saveToFolderId = folderId
      }
      this.editVM.toggleIsTemplate()
      this.editVM.setIsSurveyTemplateEdit(true)
      return
    }
    const svc = new SurveyTemplatesService(this.rootStore)
    const foundTemplate = await svc.getSurveyTemplate(id, orgId)
    const surveyTemplate = deserialize(SurveyTemplate, foundTemplate)
    surveyTemplate.published = false
    surveyTemplate.isTemplate = true
    surveyTemplate.isFromSystemTemplate = true
    this.editVM = new SurveyEditVM(this.rootStore, surveyTemplate)
    this.editVM.setIsSurveyTemplateEdit(true)
    this.editVM.survey.setPublishedByUserId(foundTemplate.createdByUserId)
    return
  }

  @action
  public async loadSurveyEditVM(id: string, attempts: number = 0): Promise<SurveyEditVM> {
    if (attempts === 14) return
    if (!this.rootStore.organizationsStore.currentOrganization) {
      setTimeout(() => this.loadSurveyEditVM(id, attempts++), 500)
      return
    }
    if (id === 'empty') this.editVM = null
    else if (id === 'new')
      this.editVM = new SurveyEditVM(
        this.rootStore,
        Survey.create(this.rootStore.appStore.currentOrgId, this.rootStore.appStore.currentUserId)
      )
    else if (id === 'forusertask')
      this.editVM = new SurveyEditVM(
        this.rootStore,
        Survey.create(this.rootStore.appStore.currentOrgId, this.rootStore.appStore.currentUserId)
      )
    else {
      const foundSurvey = await this.surveysStore.getFullRecord(id)
      if (!foundSurvey) {
        this.loadSurveyEditVM(id)
        return
      }
      this.editVM = new SurveyEditVM(this.rootStore, foundSurvey.clone())
    }
    return this.editVM
  }

  @action
  public async loadSurveyEditVMForEvent(eventId: string) {
    const svy = Survey.create(
      this.rootStore.appStore.currentOrgId,
      this.rootStore.appStore.currentUserId
    )
    svy.setEventId(eventId)
    this.editVM = new SurveyEditVM(this.rootStore, svy)
  }

  @action
  public async createSurveyFromTemplateForUserTask(
    templateId: string,
    userTaskId: string,
    userId: string,
    attempts: number = 0
  ) {
    const maxAttempts = 14
    attempts = attempts + 1
    if (attempts === maxAttempts) {
      console.log('giving up')
      return
    }
    console.log('trying ' + attempts)
    let foundSurvey: Survey = null
    if (templateId !== 'new') {
      // await this.rootStore.surveyTemplatesStore.getFullRecord(templateId)
      // foundSurvey = this.rootStore.surveyTemplatesStore.getSurveyTemplate(templateId)
      // if (!foundSurvey) {
      //   setTimeout(
      //     () =>
      //       this.createSurveyFromTemplateForUserTask(templateId, userTaskId, userId, attempts++),
      //     500
      //   )
      //   if (attempts === 13) console.log('survey template not found: ' + templateId)
      //   return
      // }
    } else {
      foundSurvey = Survey.create(
        this.rootStore.appStore.currentOrgId,
        this.rootStore.appStore.currentUserId
      )
    }

    const foundUserTask = this.rootStore.userTasksStore.getUserTask(userTaskId)
    if (!foundUserTask) {
      setTimeout(
        () => this.createSurveyFromTemplateForUserTask(templateId, userTaskId, userId, attempts++),
        500
      )
      if (attempts === 13) console.log('user task not found: ' + userTaskId)
      return
    }

    const foundTask = this.rootStore.tasksStore.getTask(foundUserTask.taskId)
    if (!foundTask) {
      setTimeout(
        () => this.createSurveyFromTemplateForUserTask(templateId, userTaskId, userId, attempts++),
        500
      )
      if (attempts === 13) console.log('task not found: ' + foundUserTask.taskId)
      return
    }

    const foundUser = this.rootStore.audienceMembersStore.getUser(foundTask.assignedByUserId)
    if (!foundUser) {
      setTimeout(
        () => this.createSurveyFromTemplateForUserTask(templateId, userTaskId, userId, attempts++),
        500
      )
      if (attempts === 13) console.log('user not found: ' + foundTask.assignedByUserId)
      return
    }

    this.editVM = new SurveyEditVM(this.rootStore, foundSurvey.clone(), [], false, true)
    this.editVM.feedbackParticipantsSelectVM.setParticipants([
      {
        id: userId,
        type: 'user' as ParticipantType,
        name: '',
      },
    ])

    this.editVM.setName('Feedback Request: ' + foundTask.name)
    this.editVM.toggleIsTemplate()
    this.editVM.setTemplate(templateId)
    this.editVM.setUserTask(userTaskId)
    this.editVM.setPublishedByUser(this.rootStore.appStore.currentUserId)
  }

  @action
  public async createSurveyFromTemplate(templateId: string, attempts: number = 0) {
    this.editVM = undefined
    const maxAttempts = 50
    attempts = attempts + 1
    if (attempts === maxAttempts) {
      console.log('giving up')
      this.rootStore.appStore.router.push('/dashboard/surveys')
      return
    }
    console.log('trying ' + attempts)
    if (!this.rootStore.organizationsStore.currentOrganization) {
      setTimeout(async () => await this.createSurveyFromTemplate(templateId, attempts++), 500)
      if (attempts === maxAttempts - 1) console.log('org settings not loaded')
      return
    }
    const foundSurvey = await this.surveysStore.getFullRecord(templateId)
    if (!foundSurvey) {
      setTimeout(async () => await this.createSurveyFromTemplate(templateId, attempts++), 500)
      if (attempts === maxAttempts - 1) console.log('survey template not found: ' + templateId)
      return
    }
    setTimeout(() => {
      runInAction(() => {
        const newSurvey = foundSurvey.clone()
        newSurvey.clearObjectId()
        newSurvey.setTemplate(templateId)
        newSurvey.setPublishedByUserId(this.rootStore.appStore.currentUserId)
        this.editVM = new SurveyEditVM(this.rootStore, newSurvey, [], false, true)
      })
    }, 1500)
  }

  @action
  public async createSurveyFromSystemTemplate(systemTemplateId: string, attempts: number = 0) {
    this.editVM = undefined
    const maxAttempts = 50
    attempts = attempts + 1
    if (attempts === maxAttempts) {
      console.log('giving up')
      this.rootStore.appStore.router.push('/dashboard/surveys')
      return
    }
    console.log('trying ' + attempts)
    if (!this.rootStore.organizationsStore.currentOrganization) {
      setTimeout(
        async () => await this.createSurveyFromSystemTemplate(systemTemplateId, attempts++),
        500
      )
      if (attempts === maxAttempts - 1) console.log('org settings not loaded')
      return
    }
    const svc = new SurveyTemplatesService(this.rootStore)
    const foundSurvey = await svc.getSurveyFromSystemTemplate(
      systemTemplateId,
      this.rootStore.appStore.currentOrgId
    )
    if (!foundSurvey) {
      setTimeout(
        async () => await this.createSurveyFromSystemTemplate(systemTemplateId, attempts++),
        500
      )
      if (attempts === maxAttempts - 1)
        console.log('system survey template not found: ' + systemTemplateId)
      return
    }
    setTimeout(() => {
      runInAction(() => {
        const newSurvey = deserialize(Survey, foundSurvey)
        newSurvey.clearObjectId()
        newSurvey.setTemplate(systemTemplateId)
        newSurvey.setOrganizationId(this.rootStore.appStore.currentOrgId)
        newSurvey.setPublishedByUserId(this.rootStore.appStore.currentUserId)
        newSurvey.isFromSystemTemplate = true
        this.editVM = new SurveyEditVM(this.rootStore, newSurvey, [], false, true)
        this.editVM.loadDefaultEmailFrom()
      })
    }, 1500)
  }

  @action
  public async createNewSurveyFromEvent(eventId: string, userId: string, attempts: number = 0) {
    const maxAttempts = 14
    attempts += 1
    if (attempts === maxAttempts) {
      console.log('giving up')
      return
    }

    const foundEvent = await this.rootStore.eventsStore.getFullRecord(eventId)
    if (!foundEvent) {
      setTimeout(() => this.createNewSurveyFromEvent(eventId, userId, attempts++), 500)
      if (attempts === 13) console.log('event not found: ' + eventId)
      return
    }
    this.editVM = new SurveyEditVM(
      this.rootStore,
      Survey.create(this.rootStore.appStore.currentOrgId, this.rootStore.appStore.currentUserId),
      [],
      true
    )
    this.editVM.setEvent(eventId)
    this.editVM.participantsSelectVM.setCurrentTab(5)
  }

  @action
  public createNewSurveyForUser(userId: string, attempts: number = 0) {
    const maxAttempts = 34
    attempts += 1
    if (attempts === maxAttempts) {
      console.log('giving up loading survey for user')
      return
    }

    const foundUser = this.rootStore.audienceMembersStore.audienceUsers.find(
      (user) => user.objectId === userId
    )
    if (!foundUser) {
      setTimeout(() => this.createNewSurveyForUser(userId, attempts++), 1000)
      if (attempts === maxAttempts - 1) console.log('user not found: ' + userId)
      return
    }

    this.editVM = new SurveyEditVM(
      this.rootStore,
      Survey.create(this.rootStore.appStore.currentOrgId, this.rootStore.appStore.currentUserId),
      [],
      false
    )
    this.editVM.setPublishedByUser(this.rootStore.appStore.currentUserId)
    setTimeout(
      () => this.editVM.participantsSelectVM.addParticipantFromAudienceMember(foundUser),
      1000
    )
  }

  @action
  public createNewSurveyForRole(roleId: string, attempts: number = 0) {
    const maxAttempts = 14
    attempts += 1
    if (attempts === maxAttempts) {
      console.log('giving up loading survey for role')
      return
    }

    const foundRole = this.rootStore.audienceMembersStore.allCurrentOrgRoles.find(
      (role) => role.objectId === roleId
    )
    if (!foundRole) {
      setTimeout(() => this.createNewSurveyForRole(roleId, attempts++), 500)
      if (attempts === 13) console.log('role not found: ' + roleId)
      return
    }

    this.editVM = new SurveyEditVM(
      this.rootStore,
      Survey.create(this.rootStore.appStore.currentOrgId, this.rootStore.appStore.currentUserId),
      [],
      false
    )
    this.editVM.setPublishedByUser(this.rootStore.appStore.currentUserId)
    setTimeout(
      () => this.editVM.participantsSelectVM.addParticipantFromAudienceMember(foundRole),
      1000
    )
  }

  @action
  public createNewSurveyForGroup(groupId: string, attempts: number = 0) {
    const maxAttempts = 14
    attempts += 1
    if (attempts === maxAttempts) {
      console.log('giving up loading survey for group')
      return
    }

    const foundGroup = this.rootStore.audienceMembersStore.allCurrentOrgGroups.find(
      (group) => group.objectId === groupId
    )
    if (!foundGroup) {
      setTimeout(() => this.createNewSurveyForGroup(groupId, attempts++), 500)
      if (attempts === 13) console.log('group not found: ' + groupId)
      return
    }

    this.editVM = new SurveyEditVM(
      this.rootStore,
      Survey.create(this.rootStore.appStore.currentOrgId, this.rootStore.appStore.currentUserId),
      [],
      false
    )

    this.editVM.setPublishedByUser(this.rootStore.appStore.currentUserId)
    setTimeout(
      () => this.editVM.participantsSelectVM.addParticipantFromAudienceMember(foundGroup),
      1000
    )
  }

  @action
  public setManageSurveysFilter(filter) {
    this.manageSurveysFilter = filter
  }

  @action
  public clearEditVM() {
    this.editVM = null
  }
}
