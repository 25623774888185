import { observable, action, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import TaskTemplate from './aggregate/TaskTemplate'
import TaskTemplateEditForm from './forms/TaskTemplateEditForm'
import TaskTemplateNewForm from './forms/TaskTemplateNewForm'
import { ITaskTemplateDTO } from './dtos/ITaskTemplateDTO'
import { DataStore } from '../../shared/data/DataStore'

export class TaskTemplatesStore extends DataStore<TaskTemplate, ITaskTemplateDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, TaskTemplate, 'taskTemplates', ['organizationId', 'name', 'participants'])
  }

  @observable public selectedTaskTemplateId: string
  // @observable public editForm: TaskTemplateEditForm = null
  @observable public newForm: TaskTemplateNewForm = null
  @observable public showDrawer: boolean = false
  @observable public showDeleteDialog: boolean = false
  @observable public loaded: boolean = false

  @computed
  public get taskTemplates(): Array<TaskTemplate> {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.loaded = true
  }

  @action
  public setSelectedTaskTemplate(id) {
    this.selectedTaskTemplateId = id
  }

  @action clearForms() {
    // this.editForm = null
    // this.newForm = null
    this.selectedTaskTemplateId = null
  }

  @action
  toggleDeleteDialog() {
    this.showDeleteDialog = !this.showDeleteDialog
  }

  @action
  public setAddNewTaskTemplate() {
    this.selectedTaskTemplateId = null
  }

  @computed
  public get currentOrgTaskTemplates() {
    return this.taskTemplates
  }

  public getTaskTemplate(objectId): TaskTemplate {
    return this.taskTemplates.find((e) => e.objectId === objectId)
  }

  private getTaskTemplateIndex(objectId): number {
    return this.taskTemplates.findIndex((e) => e.objectId === objectId)
  }

  public getTaskTemplateByName(name): TaskTemplate {
    return this.taskTemplates.find((e) => e.name === name)
  }

  public setRootStore(root) {
    this.rootStore = root
  }

  private deleteTaskTemplate(objectId) {
    this.taskTemplates.splice(this.getTaskTemplateIndex(objectId), 1)
  }
}
